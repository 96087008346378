import React                           from "react";
import { FC }                          from "react";
import { SetStateAction }              from "react";
import { Dispatch }                    from "react";
import { MutableRefObject }            from "react";
import { useContext }                  from "react";
import { createContext }               from "react";
import { useState }                    from "react";
import { useParams }                   from "@relcu/react-router";
import { Field }                       from "@relcu/form";
import { OnChange }                    from "@relcu/form";
import { FormSpy }                     from "@relcu/form";
import { IconButton }                  from "@relcu/rc";
import { ToggleDropdown }              from "@relcu/rc";
import { Icon }                        from "@relcu/rc";
import { Form }                        from "@relcu/rc";
import { Button }                      from "@relcu/rc";
import { TabItem }                     from "@relcu/rc";
import { TabsSwitcher }                from "@relcu/rc";
import { TabPanel }                    from "@relcu/rc";
import { TabPanelContainer }           from "@relcu/rc";
import { TabContainer }                from "@relcu/rc";
import { Container }                   from "@relcu/rc";
import { Typography }                  from "@relcu/rc";
import { Page }                        from "@relcu/rc";
import { Toolbar }                     from "@relcu/rc";
import { Header }                      from "@relcu/rc";
import { Content as RCContent }        from "@relcu/rc";
import { TypographyColor }             from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { Alignment }                   from "@relcu/ui";
import { Tooltip }                     from "@relcu/ui";
import { toFirstUpper }                from "@relcu/ui";
import { defaultMutators }             from "@relcu/ui";
import { HorizontalDivider }           from "@relcu/ui";
import { EditorRef }                   from "react-email-editor/dist/types";
import { toFirstLower }                from "../../../../../utils/helpers";
import { getReplacementParams }        from "../../../../../utils/schemaUtils";
import { DirtyDialogController }       from "../../../../Generation";
import { GetFlyers_flyers_edges_node } from "../__types__/GetFlyers";
import { FlyerEditor }                 from "./FlyerEditor";
import { FlyerDetails }                from "./FlyerDetails/FlyerDetails";
import { useContent }                  from "./useContent";

export interface ContentProps {
  expand: boolean;
  setExpand: any;
  flyer?: GetFlyers_flyers_edges_node;
  onDuplicate?(data: GetFlyers_flyers_edges_node);
  onRemove?(id: string);
  hasPermissions: boolean;
  replaceableFieldsSources?: string[];
}

const fieldsTabs = {
  "title": 0,
  "content": 1
};

export const Content: FC<ContentProps> = React.memo(function Content(props) {
  const {
    expand,
    setExpand,
    flyer,
    onDuplicate,
    onRemove,
    hasPermissions,
    replaceableFieldsSources
  } = props;
  const {
    handleFullScreen,
    setLoadingEditor,
    emailEditorRef,
    checkCanAction,
    loadingEditor,
    isFullScreen,
    initialValues,
    handleSubmit,
    isGenerating,
    canUpdate,
    flyerId,
    isLO
  } = useContent(props);
  const params = useParams();
  const [replacementParams, setReplacementParams] = useState({});

  const handleApplyToChange = (value, availableForType) => {
    if (value && availableForType) {
      const params = getReplacementParams(["User", "Settings", toFirstUpper(value ?? "")].map(v => toFirstUpper(v)), availableForType ?? "email");
      const result = {};

      params.map(replacement => {
        if (!result[ replacement.role ]) {
          result[ replacement.role ] = {
            name: replacement.role,
            mergeTags: {}
          };
        }

        result[ replacement.role ].mergeTags[ replacement.value ] = {
          value: replacement.value,
          name: replacement.label,
          sample: replacement.value
        };
      });
      setReplacementParams(result);
    }
  };

  return <Container style={{ overflowX: "auto" }} key={params[ "*" ] || flyer?.id}>
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{
        ...defaultMutators,
        setFieldState: (args, state) => {
          const [error, field] = args;
          const formField = state.fields[ field ];
          if (formField) {
            if (error) {
              formField.data = { ...field.data, error };
            } else {
              formField.data = {};
            }
          }
        }
      }}
      fluid
      className={"mail-template-form"}>
      <FlyerContext.Provider value={{
        replaceableFieldsSources: replaceableFieldsSources.map(rep => toFirstLower(rep)),
        emailEditorReplacementParams: replacementParams,
        onEditorLoad: setLoadingEditor,
        loadingEditor,
        emailEditorRef,
        hasPermissions,
        isGenerating,
        flyer,
        isLO
      }}>
        <Header>
          <Toolbar childrenRenderMode={"clone"} spacing={16} size={expand ? "md" : "sm"}
                   style={{
                     padding: `0  16px 0 ${expand ? "16px" : "8px"}`,
                     height: "56px",
                     verticalAlign: "center"
                   }}>
            {
              !expand &&
              <Page.Toggle size={"md"} expand={expand} onToggle={() => setExpand(expand => !expand)}/>
            }
            <Field name={"availableForType"}>
              {
                ({ input }) => (
                  <OnChange
                    name={"applyTo"}
                    children={(value) => handleApplyToChange(value, input.value)}/>
                )
              }
            </Field>
            <FormSpy
              subscription={{ initialValues: true }}
              onChange={({ initialValues }) => {
                setTimeout(() => {
                  handleApplyToChange(initialValues.applyTo, initialValues.availableForType);
                }, 0);
              }}/>
            <FormSpy subscription={{ values: true }}>
              {
                ({ values }) => {
                  return (
                    (flyer?.id && hasPermissions) ?
                      <ToggleDropdown
                        onClick={(event) => event.stopPropagation()}
                        toggleRenderer={
                          <Button
                            size={"xs"}
                            appearance={"subtle"}
                            endIcon={<Icon type="keyboard_arrow_down"/>}>
                            {values?.title}
                          </Button>
                        }
                        placement={"autoVerticalEnd"}>
                        {
                          canUpdate &&
                          <ToggleDropdown.Item
                            eventKey={3}
                            onSelect={() => onDuplicate({ ...flyer, ...values })}>
                            <Icon type="content_copy"/>
                            Duplicate
                          </ToggleDropdown.Item>
                        }
                        <ToggleDropdown.Item
                          eventKey={3}
                          onSelect={() => onRemove(flyer.id)}>
                          <Icon type="delete"/>
                          Remove
                        </ToggleDropdown.Item>
                      </ToggleDropdown> :
                      <Typography variant={"base16"}>
                        {values?.title}
                      </Typography>
                    // <Button
                    //   disabled
                    //   size={"xs"}
                    //   appearance={"subtle"}>
                    //   {values?.title}
                    // </Button>
                  );
                }
              }
            </FormSpy>
            {!isGenerating && <Box container gap={"XXXS"} alignItems={"center"}>
              <Icon size={16} style={{ color: "var(--color-gray-tertiary)" }} type={"info"}/>
              <Typography variant={"small12"} color={TypographyColor.Tertiary} style={{ overflow: "hidden" }}>
                Temporary Edits – Changes made here are for sending purposes only and will not be saved to the original
                template.
              </Typography>
            </Box>}
            <div style={{ flexGrow: 1 }}/>
            {
              hasPermissions &&
              <FormSpy subscription={{ values: true }}>
                {
                  ({ values }) => {
                    const canAction = checkCanAction(values);
                    return <Button size={"xs"} type={"submit"} disabled={canAction ? loadingEditor : true}>
                      {
                        canAction ?
                          (flyerId == "create" ? "CREATE" : "SAVE") + " FLYER" :
                          <Tooltip title={"Please complete all information necessary for flayer creation."}
                                   alignment={Alignment.Top}>
                            <p> {flyerId == "create" ? "CREATE" : "SAVE"} FLYER</p>
                          </Tooltip>
                      }
                    </Button>;
                  }
                }
              </FormSpy>
            }
            <IconButton
              onClick={handleFullScreen}
              icon={<Icon type={isFullScreen ? "close_fullscreen" : "open_in_full"}/>}
              style={{ color: "var(--rc-accent-03-primary)" }}
              appearance={"subtle"}/>
          </Toolbar>
        </Header>
        <FlyerContent/>
      </FlyerContext.Provider>
    </Form>
  </Container>;
});

const FlyerContent = () => {
  const { hasPermissions, isGenerating } = useContext(FlyerContext);
  const [tab, setTab] = useState(0);

  return <RCContent style={{ overflow: "overlay", flex: 1 }}>
    <DirtyDialogController
      header={!isGenerating ? "Changes will not be saved" : "Unsaved changes"}
      title={!isGenerating ? "The changes you have made will be lost if you proceed." : "You have unsaved changes that will be lost if you proceed."}
      subTitle={!isGenerating ? "Are you sure you want to continue?" : "Are you sure you want to continue without saving?"}
    />
    <FormSpy
      subscription={{ submitErrors: true }}
      onChange={({ submitErrors }) => {
        const invalidField = submitErrors && fieldsTabs[ Object.keys(submitErrors)[ 0 ] ];
        if (invalidField != null) {
          setTimeout(() => {
            setTab(invalidField);
          }, 0);//todo workaround
        }
      }}
    />
    <TabContainer gap={11} outerState={[tab, setTab]} className={"mail-template-tab-container"}>
      <TabPanelContainer>
        {
          hasPermissions &&
          <TabPanel>
            <FlyerDetails/>
          </TabPanel>
        }
        <TabPanel>
          <FlyerEditor/>
        </TabPanel>
      </TabPanelContainer>
      <TabsSwitcher itemsWidth={145}>
        {
          hasPermissions &&
          <TabItem active={true}>
            Flyer details
          </TabItem>
        }
        <TabItem active={false}>
          Layout and content
        </TabItem>
      </TabsSwitcher>
      <HorizontalDivider className={"mail-template-controller-divider"}/>
    </TabContainer>
  </RCContent>;
};

export const FlyerContext = createContext<{
  flyer: GetFlyers_flyers_edges_node
  emailEditorRef: MutableRefObject<EditorRef>
  emailEditorReplacementParams: Record<string, any>
  onEditorLoad: Dispatch<SetStateAction<boolean>>
  loadingEditor: boolean,
  hasPermissions: boolean,
  isGenerating: boolean,
  isLO: boolean,
  replaceableFieldsSources: string[]
}>(null);


