import React                  from "react";
import { FC }                 from "react";
import { Box }                from "../../../Box";
import { Link }               from "../../../Link";
import { CellClasses }        from "../CellClasses";
import { ICellProps }         from "../ICellProps";

export interface LinkCellProps extends ICellProps {
  alt?: string;
}

export const LinkCell: FC<LinkCellProps> = React.memo(function LinkCell({ rowData, dataKey, className, getCellValue }) {
    const value = getCellValue(rowData, dataKey);
    return <Box className={className}>
      <Link to={`/contact/${"value.objectId"}`} className={CellClasses.AvatarCellLink}>
        {value}
      </Link>
    </Box>;
  }
);
