import { SetStateAction }          from "react";
import { Dispatch }                from "react";
import { useState }                from "react";
import React                       from "react";
import { FC }                      from "react";
import { useMemo }                 from "react";
import { Form }                    from "@relcu/form";
import { ReactiveVar }             from "@apollo/client";
import { StepInterface }           from "@relcu/ui";
import { Stepper }                 from "@relcu/ui";
import { ModalFooter, ModalProps } from "@relcu/ui";
import { ModalBody }               from "@relcu/ui";
import { Modal }                   from "@relcu/ui";
import { defaultMutators }         from "@relcu/ui";
import { Box }                     from "@relcu/ui";
import { ButtonVariants }          from "@relcu/ui";
import { Button }                  from "@relcu/ui";
import { IBaseField }              from "../../../../../types/ISchemas";
import {
  GetDistributionQueueWithStats_distributionQueuesWithStats
}                                  from "../__types__/GetDistributionQueueWithStats";
import { Activate }                from "./DistributionQueue/Steps";
import { Schedule }                from "./DistributionQueue/Steps";
import { Appearance }              from "./DistributionQueue/Steps";
import { Limits }                  from "./DistributionQueue/Steps";
import { Rules }                   from "./DistributionQueue/Steps";
import { useDistributionDialog }   from "./useDistributionDialog";
import "./distribution-queue-dialog.css";

export interface NotCompleteRules {
  leadsFilter?: string,
  usersFilter?: string,
  sort?: string,
}

interface DistributionInterface {
  leadToUser: { [ key: string ]: any };
  sortingAndDistribution: { [ key: string ]: any };
  view: { [ key: string ]: any };
  leadFilters: { [ key: string ]: any };
  rulesState: Record<string, boolean>,
  setRulesState: Dispatch<SetStateAction<Record<string, boolean>>>,
  getFieldSchema<T = IBaseField>(field: string): T;
  getFieldOptions<T = IBaseField>(className: string, field: string): any[];
  notCompleteRulesVar: ReactiveVar<{ [ key: string ]: NotCompleteRules }>;
  notCompleteAppearanceVar: ReactiveVar<{ [ key: string ]: NotCompleteRules }>;
  steps: (StepInterface & { name: string })[];
  edit: boolean;
  clearRulesError(index: number, errorName: string);
}

export const DistributionQueueContext = React.createContext<DistributionInterface>(null);

export interface DistributionQueueDialogProps extends Partial<ModalProps> {
  edit: boolean,
  dialogProps: DistributionInterface
  data: GetDistributionQueueWithStats_distributionQueuesWithStats,
  onConfirm: (data) => void
  label?: string
  showActions?: boolean
  modalProps?: object
}

export const DistributionQueueDialog: FC<DistributionQueueDialogProps> = React.memo(function DistributionQueueDialog(props) {
  const { onClose, open, modalProps, showActions, closeIcon, edit, dialogProps } = props;
  const { step, handleSubmit, handleDraftSave, setStep, changeStep, initialValues, getFieldSchema, getFieldOptions, notCompleteRulesVar, notCompleteAppearanceVar, clearRulesError } = useDistributionDialog(props);
  const [rulesState, setRulesState] = useState({});
  const renderStep = useMemo(() => {
    const activeStep = steps.find((s, index) => index == step);
    let { component: Step, ...props } = activeStep;
    return <Step {...props}/>;
  }, [step]);
  return <Modal
    title={`${edit ? "Edit" : "Create"} distribution queue`}
    onClose={onClose}
    open={open}
    closeIcon={closeIcon}
    disableBackdropClick={false}
    variant={"large"}
    className={DistributionQueueDialogClasses.Modal}
    modalProps={modalProps}>
    <DistributionQueueContext.Provider
      value={{
        ...dialogProps,
        rulesState,
        setRulesState,
        getFieldSchema,
        getFieldOptions,
        notCompleteRulesVar: notCompleteRulesVar ?? null,
        notCompleteAppearanceVar: notCompleteAppearanceVar ?? null,
        clearRulesError,
        edit
      }}>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={defaultMutators}>
        {
          ({ form, handleSubmit }) => {
            return <form
              onSubmit={(e) => e.preventDefault()}
              noValidate
              style={{ display: "contents" }}>
              <ModalBody container direction={"column"} flex={"1 1 auto"} justify={"center"}
                         className={DistributionQueueDialogClasses.Container}>
                <Box container flex={1} style={{ overflow: "hidden" }}>
                  <Stepper steps={steps} step={step} edit={edit}
                           onStepChange={setStep}/>
                  {renderStep}
                </Box>
                {
                  showActions &&
                  <ModalFooter borderOnTop>
                    {
                      !edit &&
                      <Button
                        variant={ButtonVariants.Ghost}
                        onClick={(e) => handleDraftSave(form)}>SAVE AS DRAFT</Button>
                    }
                    <Button variant={ButtonVariants.Outline}
                            onClick={() => onClose()}>CANCEL</Button>
                    <Button
                      onClick={
                        (step == steps.length - 1 || edit)
                          ?
                          (e) => handleSubmit()
                          :
                          (e) => changeStep(e, form)}>
                      {edit ? "SAVE" : step == steps.length - 1 ? "FINISH" : "NEXT STEP"}
                    </Button>
                  </ModalFooter>
                }
              </ModalBody>
            </form>;
          }
        }
      </Form>
    </DistributionQueueContext.Provider>
  </Modal>;
});

DistributionQueueDialog.defaultProps = {
  showActions: true,
  closeIcon: true
};

export const enum DistributionQueueDialogClasses {
  Modal = "distribution-queue-dialog-modal",
  Container = "distribution-queue__container",
  Content = "distribution-queue__content",
  Footer = "distribution-queue__footer",
}

export const steps: (StepInterface & { name: string })[] = [
  {
    step: "Step 1",
    title: "Rule(s)",
    description: "Define the logic of the queue by setting rules for lead filtering and distribution.",
    name: "Rules",
    component: Rules
  },
  {
    step: "Step 2",
    title: "Limits",
    description: "Define caps for the general queue and/or selected users.",
    name: "Limits",
    component: Limits
  },
  {
    step: "Step 3",
    title: "Details",
    description: "Choose distribution type and set up its details.",
    name: "Appearance",
    component: Appearance
  },
  {
    step: "Step 4",
    title: "Schedule",
    description: "Choose the days and hours to set up the distribution schedule.",
    name: "Schedule",
    component: Schedule
  },
  {
    step: "Step 5",
    title: "Activate",
    description: "Enable to finish the queue creation.",
    name: "Activate",
    component: Activate
  }
];
