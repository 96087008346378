import { useNavigate }       from "@relcu/react-router";
import { CircularLoader }    from "@relcu/ui";
import { useRef }            from "react";
import { useState }          from "react";
import { useEffect }         from "react";
import React                 from "react";
import { SubscriptionEvent } from "../../../types/graphql-global-types";
import { buildSubscription } from "../../Layout/Jql";
import { useJqlLazyQuery }   from "../../Layout/Jql";

export const Five9 = React.memo(function Five9View(props) {
  const [fetchConference, { subscribeToMore }] = useJqlLazyQuery({
    operation: "conference",
    variables: {
      id: {
        name: "id",
        type: `ID!`
      }
    },
    fields: [
      "id",
      "objectId",
      "navigate",
      {
        scope: [
          {
            "... on Node": [
              "id"
            ]
          },
          {
            "... on Document": [
              "objectId"
            ]
          }
        ]
      }
    ]
  }, {
    operationName: "GetFive9Call",
    errorPolicy: "ignore",
    onError: (e) => {
      console.info(e);
    },
    returnPartialData: true
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const unsubscribeRef = useRef(null);
  const onData = conference => {
    if (conference && conference.scope && conference.navigate) {
      unsubscribeRef.current?.();
      navigate(`/lead/${conference.scope.objectId}`);
    }
  };
  const onCallAccepted = async callId => {
    const { documentNode: subscriptionNode } = buildSubscription({
      operation: "conferences",
      variables: {
        where: {
          name: "where",
          type: `ConferenceSubscriptionWhereInput`
        }
      },
      fields: [
        "event",
        {
          node: [
            "id",
            "objectId",
            "navigate",
            {
              scope: [
                {
                  "... on Node": [
                    "id"
                  ]
                },
                {
                  "... on Document": [
                    "objectId"
                  ]
                }
              ]
            }
          ]
        }
      ]
    }, { operationName: "SubscribeFive9Call" });
    unsubscribeRef.current = subscribeToMore({
      document: subscriptionNode,
      updateQuery: (previousQueryResult, { subscriptionData: { data: { conferences: { node } } } }) => {
        onData(node);
        return previousQueryResult;
      },
      variables: {
        where: {
          id: { equalTo: callId },
          navigate: { equalTo: true }
        }
      }
    });
    try {
      const { data: { conference } = {} } = await fetchConference({
        context: { errorPolicy: "ignore" },
        fetchPolicy: "no-cache",
        variables: { id: callId }
      });
      onData(conference);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    const interactionApi = window.Five9.CrmSdk.interactionApi();
    interactionApi.subscribe({
      callAccepted: async (params) => {
        try {
          await onCallAccepted(params.callData.callId);
        } catch (e) {
          console.error(e);
        }
      },
      callEnded: () => {
        unsubscribeRef.current?.();
      },
      interactionOpened: () => {
      },
      callStarted: () => {
      },
      callFinished: () => {
      }
    });
  }, []);
  useEffect(() => unsubscribeRef.current, []);

  return (
    <>
      <iframe
        frameBorder="0"
        onLoad={() => setLoading(false)}
        src="https://app.five9.com/clients/integrations/adt.main.html?f9verticalthreshold=500px"
        style={{ position: "relative", height: "100%", width: "100%" }}/>
      {loading &&
        <CircularLoader justify={"center"} style={{ right: "50%", position: "absolute" }} alignSelf={"center"}/>}
    </>
  );
});
