import React                    from "react";
import { classNames }           from "../../..";
import { useBoxProps }          from "../../..";
import { GlobalClasses }        from "../../..";
import { toFirstUpper }         from "../../../utils/helpers";
import { Box }                  from "../../Box";
import { Label }                from "../../Label";
import { BaseFieldClasses }     from "../BaseField";
import { useConsumeArrayField } from "../BaseField";
import { EmailsFieldProps }     from "./EmailsField";
import { LinkExternal }         from "../../LinkExternal";
import { LinkExternalColors }   from "../../LinkExternal";

export const EmailsReadField = React.memo(function EmailsReadField(props) {
  const { fields, input: { readPlaceholder, label, ...inputProps } } = useConsumeArrayField<EmailsFieldProps>();
  return (
    <Box
      container
      direction={"column"}
      gap={"XXXS"}
      className={BaseFieldClasses.ReadField}
      alignSelf={"start"}
      {...useBoxProps(inputProps)}>
      <Label>{label}</Label>
      <Box container direction={"column"} gap={"XXXS"}>
        {
          fields.length > 0 ?
            fields.map((name, index) => (
              <LinkExternal
                key={index}
                color={LinkExternalColors.Primary}
                to={`mailto:${fields.value[ index ].address}`}
                allowCopy
                copyText={fields.value[ index ].address}
              >
                <span key={name} className={GlobalClasses.EllipsisFlex}>
                  { fields.value[ index ].address}
                  { fields.value[ index ].type &&
                  <span className={classNames(BaseFieldClasses.ReadFieldNotAvailable, {
                    [ BaseFieldClasses.Default ]: fields.value[ index ].isPrimary
                  })}>
                      &nbsp;({toFirstUpper(fields.value[ index ].type)})
                    </span>
                  }
                </span>
              </LinkExternal>
            ))
            :
            <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
        }
      </Box>
    </Box>
  );
});

export default EmailsReadField;//todo need to test
