import React                 from "react";
import { FC }                from "react";
import { Label }             from "@relcu/ui";
import { useBoxProps }       from "@relcu/ui";
import { BaseFieldClasses }  from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { PointerFieldValue } from "@relcu/ui";
import { PointerFieldProps } from "@relcu/ui";
import { useConsumeField }   from "@relcu/ui";

export const PhoneReadField: FC<any> = React.memo(function PhoneReadField(props) {
  const { input: { readPlaceholder, label, value, thumbnail, ...inputProps } } = useConsumeField<PointerFieldProps, PointerFieldValue>();
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(inputProps)}>
      <Label>{label}</Label>
      {
        value ? <Box container gap={"XXS"} alignItems={"center"}>
          {value.objectName}
        </Box> : <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
      }
    </Box>
  );
});
