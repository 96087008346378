import React                           from "react";
import { FC }                          from "react";
import { useContext }                  from "react";
import { useCallback }                 from "react";
import { useState }                    from "react";
import { Icon }                        from "@relcu/rc";
import { Button }                      from "@relcu/rc";
import { SelectPicker }                from "@relcu/rc";
import { Field }                       from "@relcu/form";
import { Typography }                  from "@relcu/rc";
import { Stack }                       from "@relcu/rc";
import { HiddenField }                 from "@relcu/ui";
import { editor }                      from "monaco-editor";
import Editor, { loader }              from "@monaco-editor/react";
import { OnMount }                     from "@monaco-editor/react";
import { PhoneMessageTemplateContext } from "../Content";
import { PhoneMessageTemplatePreview } from "../PhoneMessageTemplatePreview";
import { EmojiDropdown }               from "./EmojiDropdown";
import {
  PhoneMessageTemplatePreviewSettings
}                                      from "../PhoneMessageTemplatePreview/PhoneMessageTemplatePreviewSettings";

const ToggleSelect = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <Button
    size={"xs"}
    color={"red"}
    style={{ background: "transparent" }}
    appearance={"text"}
    startIcon={<Icon type={"rc_data_object"} style={{ color: "var(--rc-accent-03-primary)" }}/>}>
    INSERT VARIABLE
  </Button>;
});
export const PhoneMessageTemplateEditor: FC<{
  replacements: any[]
}> = React.memo(function PhoneMessageTemplateEditor(props) {
  const { preview, setPreview } = useContext(PhoneMessageTemplateContext);
  const [monacoInstance, setMonacoInstance] = useState<editor.IStandaloneCodeEditor | null>(null);

  const handleAddParam = useCallback((item) => {
    monacoInstance.trigger("keyboard", "type", { text: item });
  }, [monacoInstance]);

  const handlePreview = () => {
    setPreview(prev => {
      return !prev;
    });
  };

  const handleAddEmoji = useCallback((emojiObject) => {
    monacoInstance.trigger("keyboard", "type", { text: emojiObject.emoji });
  }, [monacoInstance]);

  const editorMount: OnMount = (editorL: editor.IStandaloneCodeEditor) => {
    setMonacoInstance(editorL);
  };

  loader.init().then((monaco) => {
    monaco.editor.defineTheme("rc-theme", {
      base: "vs",
      inherit: true,
      rules: [],
      colors: {
        "editor.background": "#eaecf0"
      }
    });
  });

  return <Stack childrenRenderMode={"clone"} direction={"column"} style={{ flex: 1 }}>
    <HiddenField name={"title"}/>
    <HiddenField name={"useFor"}/>
    <HiddenField name={"applyTo"}/>
    <HiddenField name={"availableFor"}/>
    <HiddenField name={"enabled"}/>
    <PhoneMessageTemplatePreviewSettings onPreview={handlePreview}/>
    {
      !preview ?
        <Stack direction={"column"} style={{ flex: "1 0 50%", alignSelf: "stretch" }} childrenRenderMode={"clone"}>
          <Stack
            direction={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            className={"phone-message-template-section-header"}
            childrenRenderMode={"clone"}
          >
            <Stack.Item alignSelf={"stretch"}>
              <Typography variant={"base16"} lineHeight={"lh36"}>Editor</Typography>
            </Stack.Item>
            <Stack>
              <SelectPicker
                style={{ alignSelf: "end" }}
                data={props.replacements}
                placement={"autoVerticalEnd"}
                value={null}
                menuAutoWidth={false}
                menuStyle={{ minWidth: 300 }}
                onChange={handleAddParam}
                groupBy={"role"}
                toggleAs={ToggleSelect}
                searchable={true}
              />
              <EmojiDropdown onSelect={handleAddEmoji}/>
            </Stack>
          </Stack>
          <Stack.Item style={{ background: "var(--rc-background-tint2)", alignSelf: "stretch", flex: 1 }}
                      className={"phone-message-editor-container"}>
            <Field name={`text`}>
              {({ input: { value, onChange } }) => {
                return <Editor
                  onMount={editorMount}
                  defaultLanguage={"handlebars"}
                  value={value}
                  onChange={onChange}
                  options={{
                    minimap: { enabled: false },
                    lineNumbers: "off",
                    theme: "rc-theme"
                  }}
                />;
              }}
            </Field>
          </Stack.Item>
        </Stack>
        :
        <PhoneMessageTemplatePreview/>
    }
  </Stack>;
});
