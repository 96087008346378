import React                            from "react";
import { FC }                           from "react";
import { useEffect }                    from "react";
import { useState }                     from "react";
import { useQuery }                     from "@apollo/client";
import { useMutation }                  from "@apollo/client";
import { gql }                          from "@apollo/client";
import { IconType }                     from "@relcu/rc";
import { Stack }                        from "@relcu/rc";
import { Icon }                         from "@relcu/rc";
import { Checkbox }                     from "@relcu/rc";
import { CheckboxGroup }                from "@relcu/rc";
import { CommonClasses }                from "@relcu/ui";
import { LinkColors }                   from "@relcu/ui";
import { Link }                         from "@relcu/ui";
import { HybridCalls }                  from "../../../../types/hybridCall";
import { HybridCall as HybridCallType } from "../../../../types/hybridCall";
import { checkContactIsUnknown }        from "../../../../utils/helpers";
import { getObjectPath }                from "../../../../utils/layoutUtils";
import { usePhone }                     from "../../../usePhone";
import { Contact }                      from "../../__types__/Contact";
import { Caller }                       from "../../Caller";
import { CallStatus }                   from "../../CallStatus";
import { DialContact }                  from "../ContactList/__types__/DialContact";
import { ManageCall }                   from "../ManageCall/ManageCall";
import { DialUser }                     from "../UserList/__types__/DialUser";
import { DialBarUser }                  from "./__types__/DialBarUser";
import { ReassignLeadVariables }        from "./__types__/ReassignLead";
import { ReassignLead }                 from "./__types__/ReassignLead";
import { RelcuCallActions }             from "./RelcuCallActions";
import { TwilioCallActions }            from "./TwilioCallActions";
import { useHybridCall }                from "./useHybridCall";
import "./hybrid-call.css";

export const HybridCall: FC = React.memo(function HybridCall() {
  const { data: { viewer: { user: { username, phoneLines, objectId } = {} } = {} } = {} } = useQuery<DialBarUser>(DIAL_BAR_USER, { fetchPolicy: "cache-only" });
  const { active, invite, unHold, advancedPhone, hybridCalls, direction } = usePhone();
  const { initialDisplayCall } = Object(hybridCalls) as HybridCalls;
  const {
    myCall,
    number,
    isMerged,
    onHoldCall,
    activeCalls,
    isModerator,
    showManageCall,
    assignedToObjectId,
    secondLOActiveCall,
    allActivesInProgress,
    isLicensedStatesMatch
  } = useHybridCall();

  const [reassignLead] = useMutation<ReassignLead, ReassignLeadVariables>(REASSIGN_LEAD);
  const [mergeRules, setMergeRules] = useState(["stayOnCall", "reassignTheLead"]);
  const [voiceMessageOpen, setVoiceMessageOpen] = useState(false);
  const [dialPadOpen, setDialPadOpen] = useState(false);
  const handleCall = (value: DialUser | DialContact | Contact) => {
    let from = number;
    let to;
    if (value.__typename == "Contact") {
      to = value.phones[ 0 ].number;//todo always take 0 item from phones

      if ((!from || from.startsWith("client")) && to.startsWith("+1")) {
        from = phoneLines?.edges[ 0 ]?.node?.number;
      }
    } else {
      from = `client:${username}`;
      to = `client:${value.username}`;
    }

    invite(from, to, activeCalls[ 0 ]?.call);
  };

  const handleMerge = async () => {
    try {
      await unHold(onHoldCall.call);
      if (mergeRules.findIndex(item => item == "reassignTheLead") > -1 && initialDisplayCall?.scopeId && secondLOActiveCall) {
        reassignLead({ variables: { id: initialDisplayCall.scopeId, assignedTo: secondLOActiveCall.id } });
      }

      if (mergeRules.findIndex(item => item == "stayOnCall") == -1) {
        active.drop();
      }
    } catch (e) {
      console.error("handle merge", e);
    }
  };

  function getStatus(call: HybridCallType): any {
    if (direction == "incoming" && (call.status != "in-progress" && call.status != "completed" && call.status != "open")) {
      return "pending";
    }

    if (call.status == "in-progress" && call.hold) {
      return "hold";
    }

    return call.status;
  }

  useEffect(() => {
    if (!secondLOActiveCall || !isLicensedStatesMatch || !initialDisplayCall?.scopeId || assignedToObjectId != objectId) {
      setMergeRules(prevRules => prevRules.filter(rule => rule != "reassignTheLead"));
    }
  }, [secondLOActiveCall, isLicensedStatesMatch, initialDisplayCall?.scopeId, assignedToObjectId, objectId]);

  return <>
    {
      isModerator && !isMerged
        ?
        <div className={"hybrid-call-conference-container"}>
          {
            activeCalls?.length ?
              activeCalls?.map((call: HybridCallType, index) => {
                return <div
                  className={"hybrid-call-parties-moderator"}
                  key={index}>
                  {
                    !!activeCalls?.length &&
                    <>
                      {
                        call?.status &&
                        <CallStatus
                          timer={call?.startDate ? new Date(call?.startDate) : new Date()}
                          status={call.status == "ringing" ? null : getStatus(call)}>
                          {call.status == "ringing" && "Calling..."}
                        </CallStatus>
                      }
                      <Caller
                        hold={call.hold}
                        parties={[
                          {
                            isUnknown: checkContactIsUnknown(call.objectName),
                            name: call.objectName,
                            icon: call.objectIcon,
                            objectId: call.objectId,
                            id: call?.id,
                            number: call?.number,
                            company: call?.__typename == "Contact" && call?.company,
                            role: call?.__typename == "User" && call?.role,
                            className: call?.__typename
                          }
                        ]}
                      />
                    </>
                  } {/*todo tmp remove after switch bar collapse toggle to recluCall*/}
                  {
                    isModerator && index == 1 && onHoldCall &&
                    <CheckboxGroup name="mergeConfig" value={mergeRules} disabled={call.status != "in-progress"}
                                   onChange={(value: string[]) => setMergeRules(value)}
                                   style={{ alignSelf: "start" }}>
                      <Checkbox value="stayOnCall">
                        Staying on call after merge
                      </Checkbox>
                      <Checkbox value="reassignTheLead"
                                disabled={!isLicensedStatesMatch || !secondLOActiveCall || call.status != "in-progress" || !initialDisplayCall?.scopeId || (assignedToObjectId != objectId)}>
                        Reassign the lead
                      </Checkbox>
                    </CheckboxGroup>
                  }
                </div>;
              })
              :
              myCall &&
              <>
                {
                  myCall.status &&
                  <CallStatus
                    timer={myCall.startDate ? new Date(myCall?.startDate) : new Date()}
                    status={getStatus(myCall)}/>
                }
                <Caller
                  parties={[
                    {
                      name: myCall.objectName,
                      icon: myCall.objectIcon,
                      objectId: myCall.objectId,
                      id: myCall?.id,
                      number: myCall?.number,
                      className: "Lead"
                    }
                  ]}
                />
              </>
          }
          <RelcuCallActions
            allActivesInProgress={allActivesInProgress}
            onMerge={allActivesInProgress ? handleMerge : null}
            dialPadOpen={dialPadOpen}
            onDialPadOpen={setDialPadOpen}
            onVoiceMessagesOpen={setVoiceMessageOpen}
            voiceMessagesOpen={voiceMessageOpen}/>
        </div>
        :
        !!activeCalls?.length &&
        <>
          <div
            className={"hybrid-call-parties"}>
            {
              myCall?.status &&
              <CallStatus
                timer={myCall?.startDate ? new Date(myCall?.startDate) : new Date()}
                status={getStatus(myCall)}/>
            }
            {
              <Caller
                parties={
                  activeCalls?.map(call => {
                    return {
                      isUnknown: checkContactIsUnknown(call.objectName),
                      name: call.objectName,
                      icon: call.objectIcon,
                      id: call.id,
                      objectId: call.objectId,
                      className: call?.__typename,
                      number: call?.number,
                      company: call?.__typename == "Contact" && call?.company,
                      role: call?.__typename == "User" && call?.role
                    };
                  }) as any
                }
              />
            }
          </div>
          {
            advancedPhone ?
              <RelcuCallActions
                dialPadOpen={dialPadOpen}
                onDialPadOpen={setDialPadOpen}
                onVoiceMessagesOpen={setVoiceMessageOpen}
                voiceMessagesOpen={voiceMessageOpen}/>
              :
              <TwilioCallActions/>
          }
        </>
    }
    {
      initialDisplayCall?.scopeId &&
      <Stack justifyContent={"center"} style={{ width: "100%" }} spacing={8} childrenRenderMode={"clone"}>
        <Icon size={24} color={"primary"}
              type={initialDisplayCall.scopeClassName == "Lead" ? "rc_lead" : "rc_partner_exchange" as IconType}/>
        <Link
          color={LinkColors.Primary}
          className={CommonClasses.FlexEllipsis}
          style={{ textAlign: "center", textDecoration: "underline" }}
          to={getObjectPath({ __typename: initialDisplayCall.scopeClassName, objectId: initialDisplayCall.scopeId })}>
          {initialDisplayCall.scopeName}
        </Link>
      </Stack>
    }
    {
      showManageCall &&
      !voiceMessageOpen &&
      !dialPadOpen &&
      <ManageCall onCall={handleCall}/>
    }
  </>;
});

const REASSIGN_LEAD = gql`
  mutation ReassignLead($id:ID!,$assignedTo:ID!)
  {
    updateLead(input
    :
    {
      id:$id, fields
    :
    {
      assignedTo:{
        link: $assignedTo
      }
    }
    }
    )
    {
      lead
      {
        id
        objectId
        assignedTo
        {
          id
          objectId
        }
      }
    }
  }
`;
const DIAL_BAR_USER = gql`
  query DialBarUser {
    viewer {
      user {
        id
        objectId
        objectName
        username
        phoneLines{
          edges {
            node {
              number
            }
          }
        }
      }
    }
  }
`;
