import React                       from "react";
import { FC }                      from "react";
import { useContext }              from "react";
import { useState }                from "react";
import { useForm }                 from "@relcu/final-form";
import { FormSpy }                 from "@relcu/final-form";
import { Stack }                   from "@relcu/rc";
import { Typography }              from "@relcu/rc";
import { MailRenderer }            from "../../../../../ContentVisualizer";
import { Preview }                 from "../../../../../Generation";
import { MailTemplateContext }     from "../Content";
import { SectionHeader }           from "../SectionHeader";
import "./template-preview.css";

export const TemplatePreview: FC = React.memo(function TemplatePreview(props) {
  const { preview } = useContext(MailTemplateContext);
  const form = useForm();
  const [renderedSubject, setRenderedSubject] = useState();

  return <Preview>
    <Preview.RendererContainer>
      {
        !preview &&
        <SectionHeader>
          <Typography variant={"base16"} lineHeight={"lh20"}>Preview</Typography>
        </SectionHeader>
      }
      <Stack alignItems={"flex-start"} direction={"column"} childrenRenderMode={"clone"}
             className={"template-renderer"}>
        <Stack spacing={16} className={"template-renderer-header"}>
          <Typography color={"secondary"}>
            Subject:
          </Typography>
          <Stack.Item style={{ height: 48, padding: 16 }}>
            <Typography weights={"medium"}>
              {renderedSubject}
              {/*{rendererRef.current?.subject}*/}
            </Typography>
          </Stack.Item>
        </Stack>
        <Stack.Item style={{ padding: 16, flex: 1, overflowY: "scroll" }}>
          <FormSpy subscription={{ values: true }}>
            {
              ({ values }) => {
                return <MailRenderer
                  isTemplateRequired={true}
                  // ref={rendererRef}
                  onRender={({ subject }) => {
                    //@ts-ignore
                    subject && setRenderedSubject(subject);
                  }}
                  onError={(error) => {
                    form.mutators.setFieldState(error.message, "content");
                  }}
                  onSuccess={(data) => {
                    form.mutators.setFieldState(null, "content");
                  }}
                  subject={values.subject}
                  template={values.content ?? " "}
                  ids={{
                    userId: values.from,
                    loanProposalId: values.loanProposal,
                    scopeId: values.scope
                  }}
                  to={values.to?.map(t => t.primaryEmail)}
                />;
              }
            }
          </FormSpy>
        </Stack.Item>
      </Stack>
    </Preview.RendererContainer>
  </Preview>;
});
