import React                       from "react";
import { useContext }              from "react";
import { useMemo }                 from "react";
import { useState }                from "react";
import { useCallback }             from "react";
import { Ellipsis }                from "@relcu/ui";
import { usePrevious }             from "@relcu/ui";
import { useMounted }              from "@relcu/ui";
import { useField }                from "@relcu/final-form";
import { UseFieldConfig }          from "@relcu/final-form";
import { FormControlBaseProps }    from "../../@types/common";
import { createNullableValidator } from "../../FormField/FormField";
import { createRequireValidator }  from "../../FormField/FormField";
import { useInputNumber }          from "../../FormField/FormInputNumber";
import { InputCellProps }          from "../../index";
import { Button }                  from "../../index";
import { SelectPickerProps }       from "../../SelectPicker";
import { CheckPickerCell }         from "../../TableCell";
import { CheckboxCell }            from "../../TableCell";
import { InputCell }               from "../../TableCell";
import { CellProps }               from "../../TableHorizontal/Cell";
import { CellGroupContext }        from "../../TableHorizontal/CellGroup";
import { composeValidators }       from "../../utils/composeValidators";
import { CurrencyCellProps }       from "../CurrencyCell";
import { useCurrencyNumber }       from "../CurrencyCell/CurrencyCell";
import ImageCell                   from "../ImageCell";
import CurrencyCell                from "../CurrencyCell";
import ReadCell                    from "../ReadCell";
import SelectCell                  from "../SelectCell";

export interface FieldCellProps<P = any, ValueType = any> extends UseFieldConfig<ValueType>, Omit<CellProps, "defaultValue" | "value"> {
  name: string;
  type?: string;
  helperText?: string;
  waring?: boolean;
  plaintext?: boolean;
  prefix?: string;
  required?: boolean;
  notNullable?: boolean;
  highlight?: boolean;
  showError?: boolean;
  component?: React.ElementType<P & FormControlBaseProps<ValueType>>;
  properties?: Partial<P>;
}

export interface CellFieldComponent<P = any> extends React.FC<FieldCellProps<P>> {
  <P = any>(
    props: FieldCellProps<P>
  ): React.ReactElement | null;
  Number: typeof NumberFieldCell;
  Currency: typeof CurrencyFieldCell;
  Checkbox: typeof CheckboxFieldCell;
  Select: typeof SelectFieldCell;
  CheckPicker: typeof CheckPickerFieldCell;
  Read: typeof ReadFieldCell;
  Text: typeof TextFieldCell;
  PmiCell: typeof PMIFieldCell;
  TitleCell: typeof TitleFieldCell;
}

export const FieldCell: CellFieldComponent = React.memo((props: FieldCellProps) => {
  const {
    name,
    component: Component = InputCell,
    status,
    validate,
    required,
    notNullable,
    properties,
    plaintext,
    highlight = true,
    icon,
    showError = true,
    inert,
    ...config
  } = props;
  const requiredValidator = useMemo(() => required && createRequireValidator(null), [required]);
  const nullableValidator = useMemo(() => notNullable && createNullableValidator(null), [notNullable]);
  const { input, meta } = useField(name, {
    ...config,
    validate: useMemo(() => composeValidators(requiredValidator, nullableValidator, validate), [requiredValidator, nullableValidator, validate])
  });
  const [isSuccess, setIsSuccess] = useState(false);
  // if (name == "condoCertificateFee") {
  //   console.info(input.value);
  // }
  const hasError = meta.error && meta.touched;
  const wasActive = usePrevious(meta.active);
  const prevValue = usePrevious(input.value);
  useMounted(() => {
    if (highlight && !wasActive && !meta.active && !meta.submitting && prevValue !== input.value) {
      setIsSuccess(true);
    }
  }, [input.value, prevValue, wasActive, meta.active, meta.submitting, highlight]);

  const onAnimationEnd = useCallback(() => {
    setIsSuccess(false);
  }, []);
  const context = useContext(CellGroupContext);

  if (plaintext) {
    return <ReadCell status={hasError ? "error" : (isSuccess ? "success" : status)}
                     {...input}
                     helperText={!showError ? null : hasError ? meta.error : props.helperText}
                     {...properties} inert={inert ?? context?.disabled} onAnimationEnd={onAnimationEnd}
                     icon={icon}/>;

  }

  return (<Component status={hasError ? "error" : (isSuccess ? "success" : status)}
                     {...input}
                     {...properties}
                     helperText={!showError ? null : hasError ? meta.error : props.helperText}
                     inert={inert ?? context?.disabled} onAnimationEnd={onAnimationEnd}
                     icon={icon}/>);
}) as unknown as CellFieldComponent;

export interface NumberFieldCellProps extends Omit<FieldCellProps, "component"> {
  precision?: number;
}

export const NumberFieldCell = React.memo((props: NumberFieldCellProps) => {
  const { parse: propPars, format: propFormat, ...rest } = props;
  const { parse, format } = useInputNumber(props);
  return <FieldCell
    parse={parse}
    format={format}
    component={NumberCell}
    {...rest}
    properties={{
      space: true,
      className: "rc-cell-number",
      helperText: props.helperText,
      justify: props.justify
    }}/>;
});

export interface CurrencyFieldCellProps extends Omit<FieldCellProps<CurrencyCellProps>, "component"> {
  precision?: number;
  dependedFieldName?: string;
}

export const CurrencyFieldCell = React.memo((props: CurrencyFieldCellProps) => {
  const { input: unitField } = useField(`${props.name}Unit`);
  const previousCalculationUnit = usePrevious(unitField.value);
  const { input: dependedFieldInput } = useField(props.dependedFieldName ?? "");
  const { parse, format } = useCurrencyNumber({ ...props, dependedFieldValue: dependedFieldInput.value, calculationUnit: unitField.value, previousCalculationUnit });
  return <FieldCell
    {...props}
    parse={parse}
    format={format}
    component={CurrencyCell}
    showError={true}
    properties={{
      helperText: props.helperText,
      style: props.style,
      calculationUnit: unitField.value,
      onCalculationUnitChange: unitField.onChange
    }}/>;
});

const NumberCell = React.memo<InputCellProps>(props => {
  const onChange = useCallback((event) => {
    let cursor = event.target.selectionEnd;
    props.onChange(event);
    event.persist();
    setTimeout(() => {
      if (String(event.target.value).includes(",")) {
        const oldLength = (String(props.value).match(/,/g) || []).length;
        const newLength = (String(event.target.value).match(/,/g) || []).length;
        if (oldLength !== newLength) {
          cursor += 1;
        }
      }
      event.target.selectionStart = cursor;
      event.target.selectionEnd = cursor;
      event.target.focus();
    });
  }, [props.onChange, props.value]);
  return <InputCell {...props} onChange={onChange}/>;
});

export const CheckboxFieldCell = React.memo((props: Omit<FieldCellProps, "component">) => {
  const { children } = props;
  return <FieldCell type={"checkbox"}  {...props} component={CheckboxCell} properties={{ children }}/>;
});

export const PMIFieldCell = React.memo((props: {
  onOpen: () => void,
  loading: boolean
} & Omit<FieldCellProps, "component">) => {
  const { onOpen, loading, helperText, status, ...other } = props;
  const requiredValidator = useMemo(() => createRequireValidator(null), []);
  const context = useContext(CellGroupContext);
  const { input, meta } = useField(props.name, {
    ...other,
    validate: requiredValidator
  });

  return <ImageCell
    imageHelperText={pmiHelperTexts[ input.value ]}
    helperText={helperText || meta.error} status={meta.error ? "error" : status}
    src={loading ? null : input.value ? `../assets/pmi_logos/${input.value}_second.png` : null}
    inert={context.disabled}>
    <Button appearance={"text"} onClick={onOpen} disabled={loading} size={"xs"} loading={loading}>SELECT</Button>
  </ImageCell>;
});

export const TitleFieldCell = React.memo((props: {
    onOpen: () => void,
    loading: boolean
    title?: string
  } & Omit<FieldCellProps, "component">) => {
    const { onOpen, loading, helperText, status, title, ...other } = props;
    const requiredValidator = useMemo(() => createRequireValidator(null), []);
    const context = useContext(CellGroupContext);
    const { input, meta } = useField(props.name, {
      ...other,
      validate: requiredValidator
    });

    if (input.value === "lodestar") {
      return <ReadCell
        helperText={helperText || meta.error} status={meta.error ? "error" : status}
        inert={context.disabled} as={"div"} className={"rc-cell-title-fee"}>
        <Ellipsis from={14} title={title || titleFeeHelperTexts[ input.value ]}>
          {title || titleFeeHelperTexts[ input.value ]}
        </Ellipsis>
        <Button appearance={"text"} onClick={onOpen} disabled={loading} size={"xs"} loading={loading}>SELECT</Button>
      </ReadCell>;
    } else {
      return <ImageCell
        imageHelperText={title || titleFeeHelperTexts[ input.value ]}
        helperText={helperText || meta.error} status={meta.error ? "error" : status}
        src={loading ? null : input.value ? `../assets/titleFee_logos/${input.value}_second.png` : null}
        inert={context.disabled}>
        <Button appearance={"text"} onClick={onOpen} disabled={loading} size={"xs"} loading={loading}>SELECT</Button>
      </ImageCell>;
    }

  }
);

export interface SelectFieldCellProps extends Omit<FieldCellProps<SelectPickerProps>, "component"> {
  data: { label: string, value: string }[];
  searchable?: boolean;
  cleanable?: boolean;
}

export const SelectFieldCell = React.memo((props: SelectFieldCellProps) => {
  const { data, searchable = false, inert } = props;
  return <FieldCell {...props} component={SelectCell}
                    showError={false}
                    properties={{ data, searchable, helperText: props.helperText, style: props.style, inert }}/>;
});

export const CheckPickerFieldCell = React.memo((props: SelectFieldCellProps) => {
  const { data } = props;
  return <FieldCell {...props} component={CheckPickerCell} showError={false}
                    properties={{ data, helperText: props.helperText, style: props.style }}/>;
});

export const ReadFieldCell = React.memo((props: Omit<FieldCellProps, "component">) => {
  const { icon, disabled, style, ...rest } = props;
  return <FieldCell {...rest} properties={{ icon, disabled, style }} component={ReadCell}/>;
});
export const TextFieldCell = React.memo((props: Omit<FieldCellProps, "component">) => {
  const { icon, disabled, style, ...rest } = props;
  return <FieldCell {...rest} properties={{ icon, disabled, style }} component={InputCell}/>;
});

FieldCell.Number = NumberFieldCell;
FieldCell.Checkbox = CheckboxFieldCell;
FieldCell.Select = SelectFieldCell;
FieldCell.Currency = CurrencyFieldCell;
FieldCell.CheckPicker = CheckPickerFieldCell;
FieldCell.Read = ReadFieldCell;
FieldCell.Text = TextFieldCell;
FieldCell.PmiCell = PMIFieldCell;
FieldCell.TitleCell = TitleFieldCell;
FieldCell.displayName = "FieldCell";

const pmiHelperTexts = {
  "national": "National MI",
  "essent": "Essent",
  "enact": "Enact",
  "radian": "Radian",
  "arch": "Arch"
};

const titleFeeHelperTexts = {
  "custom": "Custom",
  "summit": "Summit Company",
  "meymax": "Meymax Company",
  "wfg": "WFG Company",
  "silk": "The silk companies",
  "solidifi": "Solidifi"
};
