import React                                   from "react";
import { FC }                                  from "react";
import { useForm }                             from "@relcu/final-form";
import { FormSpy }                             from "@relcu/final-form";
import { Stack }                               from "@relcu/rc";
import { PhoneMessageRenderer }                from "../../../../../ContentVisualizer/PhoneMessageRenderer";
import { Preview }                             from "../../../../../Generation";

export const PhoneMessageTemplatePreview: FC = React.memo(function TemplatePreview(props) {
  const form = useForm();

  return <Preview>
    <Preview.RendererContainer>
      <Stack.Item style={{ padding: 16, flex: 1, overflowY: "scroll" }}>
        <FormSpy subscription={{ values: true }}>
          {
            ({ values }) => {
              return <PhoneMessageRenderer
                onError={(error) => {
                  form.mutators.setFieldState(error.message, "text");
                }}
                onSuccess={(data) => {
                  form.mutators.setFieldState(null, "text");
                }}
                template={values.text ?? " "}
                ids={{
                  userId: values.user,
                  scopeId: values.scope,
                  contactId: values.contact
                }}
              />;
            }
          }
        </FormSpy>
      </Stack.Item>
    </Preview.RendererContainer>
  </Preview>;
});
