import { useSource }            from "@relcu/ui";
import { toFirstUpper }         from "@relcu/ui";
import React                    from "react";
import { FC }                   from "react";
import { useMemo }              from "react";
import { useEffect }            from "react";
import { useField }             from "@relcu/final-form";
import { SelectPicker }         from "@relcu/rc";
import { getReplacementParams } from "../../../../../../utils/schemaUtils";
import { usePointerListField }  from "../../../../Field/PointerListField/usePointerListField";

export const ScopePreview: FC<any> = React.memo(function ScopePreview(props) {
  const { targetClass, onChange, ...rest } = props;
  const {$viewer} = useSource()
  const { input } = useField("availableForType");
  const { input: { value: previewUser } } = useField("previewSettings.user");
  const filters = useMemo(() => {
    const query = {};

    if (previewUser && $viewer.role == "loan_officer") {
      query[ "ownerObjectId" ] = {
        "equalTo": previewUser.objectId
      };
    }

    return query;
  }, [targetClass, input.value, previewUser?.id]);

  const fields = getReplacementParams([toFirstUpper(targetClass)], input.value ?? "email");
  const {
    options: scopes,
    setQ,
    setSelectedIds
  } = usePointerListField({
    targetClass: targetClass,
    fields: fields.map(f => f.label),
    filters: filters,
    ids: props.value ? [props.value[ "objectId" ]] : []
  });

  const data = useMemo(() => {
    const data = [];
    scopes.forEach(scope => {
      if (scope.options) {
        scope.options.forEach(option => {
          data.push({ ...option, classLabel: scope.label });
        });
      } else {
        data.push(scope);
      }
    });
    return data;
  }, [scopes]);

  const handleChange = (id) => {
    if (!id) {
      return id;
    }

    return data.find(d => d.id == id);
  };

  useEffect(() => {
    setSelectedIds(props.value ? [props.value[ "objectId" ]] : []);
  }, [props.value]);

  return <SelectPicker
    style={{ width: "100%" }}
    cleanable={true}
    labelKey={"objectName"}
    valueKey={"id"}
    {...rest}
    value={props.value[ "id" ] ?? null}
    data={data}
    onSearch={(searchKeyword) => setQ(searchKeyword)}
    onChange={(value) => onChange(handleChange(value))}
  />;
});
