import React                    from "react";
import { FC }                   from "react";
import { useState }             from "react";
import { useContext }           from "react";
import { useEffect }            from "react";
import { Stack }                from "@relcu/rc";
import EmailEditor              from "react-email-editor";
import { FormSpy }              from "@relcu/form";
import { useField }             from "@relcu/form";
import { useForm }              from "@relcu/form";
import { HiddenField }          from "@relcu/ui";
import { useAlert }             from "@relcu/ui";
import { useSource }            from "@relcu/ui";
import { fileToBase64 }         from "../../../../../utils/helpers";
import { FlyerContext }         from "./Content";
import { FlyerPreviewSettings } from "./FlyerPreview/FlyerPreviewSettings";

export const FlyerEditor: FC = React.memo(function FlyerEditor() {
  const { emailEditorRef, emailEditorReplacementParams, onEditorLoad } = useContext(FlyerContext);
  const unlayer = emailEditorRef.current?.editor;
  const { error } = useAlert();
  const form = useForm();
  const contentField = useField("content");
  const scope = useField("previewSettings.scope");
  const user = useField("previewSettings.user");
  const [previewUnlayer, setPreviewUnlayer] = useState(false);
  const { $viewer } = useSource();
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(fileToBase64(reader));
    reader.onerror = reject;
  });
  function changeSample(value) {
    const typeName = value.__typename;
    const mergeTags = emailEditorReplacementParams[ typeName ]?.mergeTags;
    return {
      ...emailEditorReplacementParams[ typeName ],
      mergeTags: Object.keys(mergeTags || {}).reduce((previousValue, currentValue) => {
        return {
          ...previousValue,
          [ currentValue ]: {
            ...mergeTags[ currentValue ],
            sample: value[ mergeTags[ currentValue ].name ] ?? mergeTags[ currentValue ].sample
          }
        };
      }, {})
    };
  }
  const onLoad = (unlyer) => {
    unlyer.registerCallback("image", async function (file, done) {
      const data = new FormData();
      const attachment = file.attachments[ 0 ];
      const base64File: any = await toBase64(attachment);
      data.append("data", JSON.stringify({
        content: {
          base64: base64File
        },
        name: attachment.name,
        type: attachment.type
      }));

      fetch("/api/v1/templateFiles", {
        method: "POST",
        headers: {
          "Accept": "application/json"
        },
        body: data
      }).then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          error("Oops something went wrong");
          throw new Error("Oops something went wrong");
        }
      }).then(response => {
        return response.json();
      }).then(data => {
        // Pass the URL back to Unlayer to mark this upload as completed
        done({ progress: 100, url: data.url });
      });
    });

    const values = form.getState().values;
    if (values.jsonContent) {
      unlyer.loadDesign(values.jsonContent);
    }
  };
  const onReady = (unlyer) => {
    // editor is ready
    onEditorLoad(false);
    unlyer?.addEventListener("design:updated", function (data) {
      unlyer.exportHtml((data) => {
        const { design, html } = data;
        contentField.input.onChange(html); //todo do not change this it need to affect on dirty field
        form.change("jsonContent", design);
      });
    });
  };

  useEffect(() => {
    const newTags = {};
    if (scope.input.value) {
      newTags[ scope.input.value.__typename ] = changeSample(scope.input.value);
    }

    if (user.input.value) {
      newTags[ user.input.value.__typename ] = changeSample(user.input.value);
    }

    if (Object.keys(newTags).length) {
      unlayer?.setMergeTags({ ...emailEditorReplacementParams, ...newTags });
    }
  }, [scope, user]);

  useEffect(() => {
    onEditorLoad(true);
  }, []);

  return <Stack childrenRenderMode={"clone"} direction={"column"} style={{ flex: 1 }}>
    <HiddenField name={"title"}/>
    <HiddenField name={"availableFor"}/>
    <HiddenField name={"enabled"}/>
    <FlyerPreviewSettings
      onPreview={() => {
        setPreviewUnlayer(!previewUnlayer);
        unlayer.showPreview({ device: "desktop" });
      }}/>
    <FormSpy
      subscription={{ submitErrors: true }}
      onChange={({ submitErrors }) => {
        submitErrors?.content && error(submitErrors.content);
      }}/>
    <div style={{ display: "flex", flex: 1, width: "-webkit-fill-available" }}>
      <EmailEditor
        options={{
          user: {
            id: `${window.location.hostname}:${$viewer.objectId}`
          },
          version: "latest",
          id: "editor",
          displayMode: "document",
          projectId: window.__CONFIG__.unlayerProjectId,
          tools: {
            blocks: {
              enabled: true
            },
            social: {
              enabled: true
            },
            timer: {
              enabled: true
            },
            video: {
              enabled: true
            }
          },
          features: {
            userUploads: true,
            imageEditor: {
              enabled: true
            }
          },
          mergeTags: emailEditorReplacementParams
        }}
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
      />
    </div>
  </Stack>;
});

