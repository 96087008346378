import { useQuery }             from "@apollo/client";
import { ModalFooter }          from "@relcu/ui";
import { ModalBody }            from "@relcu/ui";
import { transformNameToLabel } from "@relcu/ui";
import React                    from "react";
import { FC }                   from "react";
import { BackDropLoader }       from "@relcu/ui";
import { ModalProps }           from "@relcu/ui";
import { Modal }                from "@relcu/ui";
import { ButtonVariants }       from "@relcu/ui";
import { Button }               from "@relcu/ui";
import { SourceProvider }       from "@relcu/ui";
import { useComponents }        from "@relcu/ui";
import { useSource }            from "@relcu/ui";
import { GET_SETTINGS }         from "../../../../graph/operations.graphql";
import { VIEWER_USER_NODE }     from "../../../../graph/operations.graphql";
import { layoutVar }            from "../../../../reactiveVars";
import { ClassForm }            from "../../Form/ClassForm";
import { JqlFormProps }         from "../../Jql/JqlForm";
import { useSchemaDialog }      from "./useSchemaDialog";
import "./schema-dialog.css";

export interface SchemaDialogProps extends Partial<ModalProps> {
  action?: "create" | "save";
  className: string;
  record?: Record<string, any>;
  formProps?: Partial<JqlFormProps>;
  [ k: string ]: any;
}

export const SchemaDialog: FC<SchemaDialogProps> = React.memo(withSource(function SchemaDialog(props) {
  const {
    initialValues,
    user,
    sections,
    jql,
    afterSubmit,
    formProps,
    action,
    className,
    loading,
    title,
    ...modal
  } = useSchemaDialog(props);

  return (
    <Modal
      {...modal}
      open={props.open}
      onClose={props.onClose}
      title={`${action === "save" ? "Edit" : "Create"} ${title ? String(title)?.toLowerCase() : transformNameToLabel(className)?.toLowerCase()}`}
      closeIcon={false}
      disableBackdropClick={false}
    >
      <ClassForm
        group={false}
        className={className}
        jql={jql}
        initialValues={initialValues}
        subscription={{ submitting: true }}
        sections={sections}
        user={user}
        afterSubmit={afterSubmit}
        {...formProps}
      >
        {({ elements, submitting }) => (
          <>
            <ModalBody style={{ position: "relative" }} container direction={"column"} gap={"XS"} flexGrow={1}
                       className="base-dialog-container">
              {elements}
              {loading && <BackDropLoader/>}
            </ModalBody>
            <ModalFooter>
              <Button disabled={submitting} type="button" variant={ButtonVariants.Outline}
                      onClick={props.onClose}>CANCEL</Button>
              <Button disabled={submitting} type="submit">{action.toUpperCase()}</Button>
            </ModalFooter>
          </>)
        }
      </ClassForm>
    </Modal>
  );
}));

function withSource(SchemaDialog) {
  return function SchemaDialogHok(props: SchemaDialogProps) {
    const layouts = layoutVar();
    const { dialogs } = useComponents();
    const { $object = {} } = useSource();
    const { data: { viewer: { user: $viewer } = { user: {} } } } = useQuery(VIEWER_USER_NODE, { fetchPolicy: "cache-only" });
    const { data: { settings: $settings } } = useQuery(GET_SETTINGS, { fetchPolicy: "cache-only" });
    const { dialog: { component, properties } } = layouts[ props.className ];
    const ComponentType = dialogs[ component ] || SchemaDialog;
    return (
      <SourceProvider source={{ $object, $viewer, $settings, ...props.record }}>
        <ComponentType {...props} {...properties}/>
      </SourceProvider>
    );
  };
}

SchemaDialog.defaultProps = {
  action: "create"
};
