import React                    from "react";
import { useImperativeHandle }  from "react";
import { useRef }               from "react";
import { useEffect }            from "react";
import { useLazyQuery }         from "@apollo/client";
import { gql }                  from "@apollo/client";
import { CircularLoader }       from "@relcu/ui";
import { RenderEmailVariables } from "./__types__/RenderEmail";
import { RenderMailVariables }  from "./__types__/RenderMail";
import { RenderMail }           from "./__types__/RenderMail";
import Email                    from "./Email";

export interface BaseRendererProps {
  template?: string;
  ids?: Record<string, string>;
  onError?(error);
  onSuccess?(data);
}
interface MailRendererProps extends BaseRendererProps {
  to?: string[];
  onRender?: (o: { html: string, subject: string }) => void;
  isTemplateRequired?: boolean;
  subject?: string;
}
export interface MailRendererRef {
  html: string;
  text: string;
  subject: string;
  visualizer: HTMLDivElement;
}

export const useMailRenderer = ({ onRender, subject, template, to, ids, isTemplateRequired = true }: MailRendererProps) => {
  const [load, result] = useLazyQuery<RenderMail, RenderMailVariables>(RENDER_MAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: { errorPolicy: "ignore" }
  });

  useEffect(() => {
    const { contactId, scopeId, loanProposalId, userId, templateId, ...rest } = ids;
    if (isTemplateRequired && !template && !templateId) {
      return;
    }
    let input: RenderEmailVariables["input"] = {
      user: userId,
      to,
      ...rest,
      contact: contactId || undefined,
      scope: scopeId || undefined,
      proposal: loanProposalId,
      subject: subject
    };

    if (!isTemplateRequired && !template) {
      input.template = "  ";
    } else if (template) {
      input.template = template;
    } else if (templateId) {
      input.templateId = templateId;
    }
    load({ variables: { input } }).then((re) => {
      onRender?.(re.data.renderMail);
    });
  }, [template, to, subject, ids, isTemplateRequired]);

  return result;
};
export const MailRenderer = React.forwardRef<MailRendererRef, MailRendererProps>(function MailRenderer({ onRender, subject, template, to, onError, onSuccess, ids, isTemplateRequired = true }, ref) {
  const result = useMailRenderer({ onRender, subject, template, to, onError, onSuccess, ids, isTemplateRequired });
  const mailRef = useRef<HTMLDivElement>();

  useImperativeHandle(ref, () => {
    return {
      get html() {
        return result.data?.renderMail?.html;
      },
      get text() {
        return result.data?.renderMail?.text;
      },
      get subject() {
        return result.data?.renderMail?.subject;
      },
      get visualizer() {
        return mailRef.current;
      }
    };
  }, [result.data, mailRef.current]);

  useEffect(() => {
    result.error && onError?.(result.error);
    result.data && onSuccess?.(result.data);
  }, [onError, result]);

  return (
    result.loading ?
      <CircularLoader
        justify={"center"}
        alignItems={"start"}
        style={{ minHeight: `${mailRef.current?.offsetHeight}px` }}/>
      :
      <Email
        ref={mailRef}
        template={result.data?.renderMail?.html || result.error?.message}/>
  );
});

export const RENDER_MAIL_QUERY = gql`
  query RenderMail($input:MailRenderInput){
    renderMail(input:$input)
  }
`;
