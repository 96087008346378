export const enum GRWorkSheetSectionClasses {
   GRWorkSheetSection = "gr-worksheet-section",
   Notes = "gr-worksheet-notes",

   Actions = "gr-worksheet-actions",
   Active = "gr-worksheet-active",
   EmptyView = "gr-worksheet-empty-view",
   ListItem = "gr-worksheet-list-item",
   ListItemContent = "gr-worksheet-list-item-content"
}
