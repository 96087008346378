import { Section }          from "@relcu/ui";
import { mode }             from "@relcu/ui";
import { JsonField }        from "@relcu/ui";
import React                from "react";
import { JsonSectionProps } from "@relcu/ui";
import { Box }              from "@relcu/ui";

export const GRWorkSheetReadSection = React.memo<JsonSectionProps>(function GRWorkSheetReadSection(props) {
  const { view, editable, ...rest } = props;
  return (
    <Box flex={1} container direction={"column"} gap={"XXS"} style={{ maxWidth: "100%" }}>
      <Section view={view} {...rest} >
        {
          props?.fields.map((field, index) => {
            return (
              <JsonField
                key={index}
                {...field}
                mode={"edit"}
                name={"" ? ["", field.name].join(".") : field.name}
                view={mode(view)}
              />
            );
          })
        }
      </Section>
    </Box>
  );
});

