import React                                       from "react";
import { useState }                                from "react";
import { useMemo }                                 from "react";
import { BaseField }                               from "@relcu/ui";
import { transformNameToLabel }                    from "@relcu/ui";
import { Fields }                                  from "@relcu/gql-query-builder";
import { ButtonVariants }                          from "@relcu/ui";
import { ButtonSizes }                             from "@relcu/ui";
import { Button }                                  from "@relcu/ui";
import { Box }                                     from "@relcu/ui";
import { PointerFieldProps as IPointerFieldProps } from "@relcu/ui";
import { permissionUtils }                         from "@relcu/ui";
import { useSource }                               from "@relcu/ui";
import { getSelectionSet }                         from "../../../../utils/graphQlUtils";
import { toFirstUpper }                            from "../../../../utils/helpers";
import { toFirstLower }                            from "../../../../utils/helpers";
import { pluralize }                               from "../../../../utils/pluralize";
import { useJqlQuery }                             from "../../Jql";
import PointerEditField                            from "@relcu/ui/src/components/Field/PointerField/PointerEditField";
import { usePointerFilters }                       from "../PointerField";
import { PhoneReadField }                          from "./PhoneReadField";

export interface PhoneFieldProps extends Partial<Omit<IPointerFieldProps, "onSelectedFilter">> {
  fields?: Fields;
  onSelectedFilter?(selected: any[]);
}

export const PhoneField = React.memo<PhoneFieldProps>(withFiltersCheck(function PhoneField({ fields, ...props }) {
  const first = useMemo(() => {
    return props?.first ?? (Array.isArray(props.targetClass) ? 3 : 10);
  }, []);
  const [q, setQ] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const className = Array.isArray(props.targetClass) ? props.targetClass[ 0 ] : props.targetClass;
  const operation = useMemo(() => pluralize(toFirstLower(className)), [className]);
  const selectionSet = useMemo(() => getSelectionSet(fields), [fields]);
  const query = useMemo(() => {
    let query: any;
    let value = {
      ...props.filters
    };

    if (selectedId) {
      value[ "objectId" ] = { equalTo: selectedId };
    }

    const variables = {
      after: "",
      first
    };

    if (q) {
      variables[ "search" ] = `${(q || "").trim()}`;
    }

    variables[ "first" ] = 10;
    variables[ "where" ] = {
      type: `${className}WhereInput`,
      value
    };

    query = {
      operation,
      variables,
      fields: [
        {
          pageInfo: [
            "endCursor",
            "startCursor",
            "hasNextPage",
            "hasPreviousPage"
          ]
        },
        {
          edges: [
            {
              node: selectionSet
            }
          ]
        }
      ]
    };
    return query;
  }, [q, props.targetClass, props.filters, selectedId]);
  const { data = { [ operation ]: { edges: [] } }, loading, fetchMore } = useJqlQuery(query, {
    skip: props.view === "read",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    operationName: `${toFirstUpper(props.name)}PhoneSelector`
  });
  const options = useMemo(() => {
    const items = [];
    if (Array.isArray(props.targetClass)) {
      Object.keys(data).forEach(t => {
        const options = data[ t ].edges.map(({ node }) => node);
        if (options.length) {
          items.push({
            label: transformNameToLabel(t),
            options: data[ t ].edges.map(({ node }) => node)
          });
        }
      });
      return items;
    }
    return (
      Object(data[ operation ])[ "edges" ] || []
    ).map(({ node }) => node);
  }, [data, operation]);
  const pageInfo = useMemo(() => {
    return Object(data[ operation ])[ "pageInfo" ];
  }, [data, operation]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        where: query.variables.where.value,
        first: query.variables.first,
        after: pageInfo.endCursor
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          [ operation ]: {
            ...prev[ operation ],
            pageInfo: fetchMoreResult[ operation ].pageInfo,
            edges: [...prev[ operation ].edges, ...fetchMoreResult[ operation ].edges]
          }
        };
      }
    });
  };
  const properties: any = {
    thumbnail: true,
    ...props,
    onLoadMoreHandler: pageInfo?.hasNextPage &&
      <Box container justify={"center"} style={{ position: "sticky", bottom: 0, background: "white", paddingTop: 8 }}>
        <Button size={ButtonSizes.Small} variant={ButtonVariants.Ghost} onClick={handleLoadMore}>Load more</Button>
      </Box>,
    onSelectedFilter: (selected) => setSelectedId(selected ? selected[ "objectId" ] : null),
    disabled: props.disabled || (props.mode == "edit" && props.edit === false),
    options,
    searchText: q,
    onType: setQ,
    loading
  };

  return (
    <BaseField
      {...properties}
      readView={<PhoneReadField/>}
      editView={<PointerEditField/>}
    />
  );
}));

function withFiltersCheck(PointerField) {
  function WithFilters(props: PhoneFieldProps) {
    const filters = usePointerFilters(props.filters);
    return <PointerField {...props} filters={filters}/>;
  }
  return function withFiltersCheckHok(props: PhoneFieldProps) {
    const { $viewer } = useSource();
    if (!props.filters) {
      return <PointerField {...props}/>;
    }
    if (!props.permissions?.filters || permissionUtils.checkAccess($viewer, props.permissions.filters)) {
      return <WithFilters {...props}/>;
    }
    return <WithFilters {...props} filters={undefined}/>;
  };
}

