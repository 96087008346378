import React                        from "react";
import { FC }                       from "react";
import { useContext }               from "react";
import { useCallback }              from "react";
import { useField }                 from "@relcu/form";
import { useForm }                  from "@relcu/form";
import { classNames }               from "@relcu/ui";
import { BoxComponentProps }        from "@relcu/ui";
import { Accordion }                from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { DistributionQueueContext } from "../../../DistributionQueueDialog";
import { Caps }                     from "../../Caps/Caps";
import { RuleHeader }               from "./RuleHeader";
import { RulesClasses }             from "./RulesClasses";
import { LeadFiltering }            from "./RuleSections/LeadFiltering";
import { LeadToUser }               from "./RuleSections/LeadToUser";
import { Sort }                     from "./RuleSections/Sort";

export interface RuleSectionInterface {
  name: string;
  index: number;
  handleClean?(name, value);
}

export interface RuleProps extends BoxComponentProps {
  incomplete?: boolean;
  name: string;
  index: number;
  onClone();
  onDelete();
}

export const Rule: FC<RuleProps> = React.memo(function Rule(props) {
  const { className, incomplete, name, onClone, onDelete, index } = props;
  const { rulesState, setRulesState } = useContext(DistributionQueueContext);
  const { input: { value: slug } } = useField(`${name}.slug`);
  const form = useForm();
  const classes = classNames(RulesClasses.Rule, {
    [ RulesClasses.Incomplete ]: incomplete
  }, className);

  const handleClean = useCallback((name, value) => {
    form.change(name, value);
  }, [form]);

  return <Accordion
    onToggle={(toggle) => {
      setRulesState((prev) => ({
        ...prev,
        [ slug ]: toggle
      }));
    }}
    collapse={rulesState[ slug ]}
    header={(open) => {
      return <RuleHeader
        name={`${name}.name`}
        onClone={onClone}
        onDelete={onDelete}
        index={index}
        open={open}
      />;
    }}
    className={classes}
  >
    <Box container direction={"column"} gap={"S"} className={RulesClasses.Body}>
      <LeadFiltering name={`${name}.leadsFilter`} index={index}/>
      <LeadToUser index={index} name={`${name}.usersFilter`}/>
      <Caps name={`${name}.limits`} title={"Lead caps"} tooltip={"Define lead caps for this specific rule."}/>
      <Sort index={index} name={`${name}.sort`} handleClean={handleClean}/>
    </Box>
  </Accordion>;
});
