import React                      from "react";
import { useBoxProps }            from "../../..";
import { classNames }             from "../../..";
import { formatPhoneNumber }      from "../../../utils/helpers";
import { toFirstUpper }           from "../../../utils/helpers";
import { Box }                    from "../../Box";
import { Label }                  from "../../Label";
import { BaseFieldClasses }       from "../BaseField";
import { useConsumeArrayField }   from "../BaseField";
import { PhoneNumbersFieldProps } from "./PhoneNumbersField";

export const PhoneNumbersReadField = React.memo(function PhoneNumbersReadField(props) {
  const {
    fields,
    input: { readPlaceholder, className, label, required, defaultValue, fullSize = true, ...phoneNumberProps }
  } = useConsumeArrayField<PhoneNumbersFieldProps>();
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(phoneNumberProps)}>
      <Label>{label}</Label>
      <Box container direction={"column"} gap={"XXXS"}>
        {
          fields.length > 0 ?
            fields.map((name, index) => (
              <span key={name}>{
                formatPhoneNumber(String(fields.value[ index ].number || ""))
              }{
                fields.value[ index ].type && <span
                  className={
                    classNames(BaseFieldClasses.ReadFieldNotAvailable, {
                      [ BaseFieldClasses.Default ]: fields.value[ index ].isPrimary
                    })
                  }>&nbsp;({toFirstUpper(fields.value[ index ].type)})</span>
              }
              </span>
            ))
            :
            <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
        }
      </Box>
    </Box>
  );
});

export default PhoneNumbersReadField;//todo need to test
