import React                    from "react";
import { FC }                   from "react";
import { useMemo }              from "react";
import { useContext }           from "react";
import { Stack }                from "@relcu/rc";
import { Icon }                 from "@relcu/rc";
import { Button }               from "@relcu/rc";
import { FormField }            from "@relcu/rc";
import { FormSpy }              from "@relcu/final-form";
import { gql }                  from "@apollo/client";
import { useSource }            from "@relcu/ui";
import { transformNameToLabel } from "../../../../../../utils/helpers";
import { toFirstLower }         from "../../../../../../utils/helpers";
import { PreviewSettings }      from "../../../../../Generation";
import { PointerListPicker }    from "../../../../Field/PointerListPicker";
import { PointerPicker }        from "../../../../Field/PointerPicker";
import { PointerPickerProps }   from "../../../../Field/PointerPicker";
import { MailTemplateContext }  from "../Content";

export const TemplatePreviewSettings: FC<{ onPreview }> = React.memo(function TemplatePreviewSettings({ onPreview }) {
  const { replaceableFieldsSources, loadingEditor } = useContext(MailTemplateContext);
  let optionalFields = useMemo(() => replaceableFieldsSources.filter(r => r != "Contact"), [replaceableFieldsSources]);
  const { $viewer } = useSource();
  const userFilters = useMemo(() => {
    if ($viewer.role == "loan_officer" || ($viewer.role == "team_manager" && !$viewer.team)) {
      return {
        objectId: { equalTo: $viewer.objectId }
      };
    } else if ($viewer.role == "team_manager") {
      return {
        team: { equalTo: $viewer.team }
      };
    }
    return {};
  }, [$viewer.role]);
  return <PreviewSettings className={"rc-email-template-preview-settings-bar"}>
    <FormField<PointerPickerProps>
      component={PointerListPicker}
      name="to"
      label="To"
      errorMessage={false}
      initialValue={[]}
      properties={{
        style: { width: "100%" },
        labelKey: "objectName",
        valueKey: "objectId",
        fields: ["primaryEmail"],
        targetClass: "Contact"
      }}
    />
    <FormField<PointerPickerProps>
      component={PointerPicker}
      name="from"
      label="From"
      errorMessage={false}
      properties={{
        cleanable: true,
        style: { width: "500px" },
        labelKey: "objectName",
        valueKey: "objectId",
        filters: userFilters,
        targetClass: "User"
      }}
    />
    <FormSpy subscription={{ values: true, initialValues: true }}>
      {
        ({ values }) => {
          return optionalFields.filter(r => values.applyTo.includes(toFirstLower(r))).map((field, index) => {
            const name = toFirstLower(field);
            return <FormField<PointerPickerProps>
              key={index}
              component={PointerPicker}
              name={name == "loanProposal" ? name : "scope"}
              label={transformNameToLabel(field)}
              errorMessage={false}
              properties={{
                cleanable: true,
                style: { width: "100%" },
                labelKey: "objectName",
                valueKey: name == "loanProposal" ? "objectId" : "id",
                targetClass: field
              }}
            />;
          });
        }
      }
    </FormSpy>
    <Stack.Item flex={1}/>
    <Button
      disabled={loadingEditor}
      onClick={onPreview}
      appearance={"text"}
      size={"xs"}
      style={{ alignSelf: "center" }}
      startIcon={<Icon type={"preview"}/>}>
      PREVIEW
    </Button>
  </PreviewSettings>;
});

export const SEND_TEST_MAIL = gql`
  mutation SendTestMail($input:MailSendInput){
    sendTestEmail(input:$input){
      id
    }
  }
`;
