import React                        from "react";
import { FC }                       from "react";
import { useClassNames }            from "@relcu/rc";
import { Typography }               from "@relcu/rc";
import { Stack }                    from "@relcu/rc";
import { WithAsProps }              from "@relcu/rc/src/@types/common";
import { RsRefForwardingComponent } from "@relcu/rc/src/@types/common";
import "./preview-settings.css";

const PreviewSettingsTitle: FC = (props: any, ref) => {
  const { className, ...rest } = props;
  const { merge, withClassPrefix } = useClassNames("preview-settings-title", "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );
  return <Typography variant={"base16"} weights={"medium"} className={classes} {...rest}/>;
};
const PreviewSettingsSection: FC = (props: any, ref) => {
  const { className, ...rest } = props;
  const { merge, withClassPrefix } = useClassNames("preview-settings-section", "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );

  return <Stack.Item alignSelf={"stretch"} className={classes} {...rest}/>;
};

export interface PreviewSettingsProps<T = string> extends WithAsProps {
  divided?: boolean;
}

export interface PreviewSettingsComponent extends RsRefForwardingComponent<"div", PreviewSettingsProps> {
  Title: typeof PreviewSettingsTitle;
  Section: typeof PreviewSettingsSection;
  // Footer: typeof PreviewSettingsFooter;
}

export const PreviewSettings: PreviewSettingsComponent = React.forwardRef((props: PreviewSettingsProps, ref) => {
  const { className, divided, style } = props;
  const { withClassPrefix, merge } = useClassNames("preview-settings-bar", "rc");
  const classes = merge(
    withClassPrefix({ divided }),
    className
  );
  return <Stack
    direction={"row"}
    childrenRenderMode={"clone"}
    className={classes}
    alignItems={"flex-start"}
    style={style}>
    {props.children}
  </Stack>;
}) as unknown as PreviewSettingsComponent;

PreviewSettings.Title = PreviewSettingsTitle;
PreviewSettings.Section = PreviewSettingsSection;
// PreviewSettings.Footer = PreviewSettingsFooter;


