import { Icon }                  from "@relcu/rc";
import { useNavigate }           from "@relcu/react-router";
import { useSource }             from "@relcu/ui";
import React                     from "react";
import { FC }                    from "react";
import { useMemo }               from "react";
import { useQuery }              from "@apollo/client";
import { useField }              from "@relcu/final-form";
import { Button, SelectPicker }  from "@relcu/rc";
import { GetMailBoxesVariables } from "../../../../../../graph/__types__/GetMailBoxes";
import { GetMailBoxes }          from "../../../../../../graph/__types__/GetMailBoxes";
import { GET_MAILBOXES }         from "../../../../../../graph/operations.graphql";

export const MailboxPreview: FC<any> = React.memo(function MailboxPreview(props) {
  const { input: { value: user } } = useField("previewSettings.user");
  const { $viewer } = useSource();
  const navigate = useNavigate();
  const { data: { mailboxes: { edges = [] } = {} } = {} } = useQuery<GetMailBoxes, GetMailBoxesVariables>(GET_MAILBOXES, {
    variables: {
      where: {
        OR: [
          { common: { equalTo: true } },
          { assignedTo: { have: { link: user.id } } }
        ],
        disabled: {
          equalTo: false
        },
        domain: {
          have: {
            disabled: {
              equalTo: false
            }
          }
        }
      }
    }
  });
  const data = useMemo(() => edges.map(({ node }) => node), [edges]);
  const handleChange = (id) => {
    if (!id) {
      return id;
    }
    return data.find(d => d.id == id);
  };

  return <SelectPicker
    {...props}
    value={props.value[ "id" ] ?? null}
    cleanable={true}
    labelKey={"address"}
    valueKey={"id"}
    data={data}
    noResultsText={<div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 4 }}>
      No email connected
      <Button
        size={"xs"}
        appearance={"text"}
        endIcon={<Icon type={"arrow_forward"}/>}
        onClick={() => {
          navigate(`/user/${$viewer.objectId}/preference`);
        }}>
        GO TO PREFERENCES
      </Button>
    </div>}
    // onSearch={(searchKeyword) => setQ(searchKeyword)}
    onChange={(value) => {
      props.onChange(handleChange(value));
    }}
    style={{ width: "100%" }}
  />;
});
