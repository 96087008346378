import React                             from "react";
import { FC }                            from "react";
import { useContext }                    from "react";
import { useMemo }                       from "react";
import { useEffect }                     from "react";
import { useState }                      from "react";
import { EmptyState }                    from "@relcu/rc";
import { useQuery }                      from "@apollo/client";
import { gql }                           from "@apollo/client";
import { Input }                         from "@relcu/rc";
import { Stack }                         from "@relcu/rc";
import { useThrottle }                   from "@relcu/ui";
import { UserWhereInput }                from "../../../../types/graphql-global-types";
import { HybridCall }                    from "../../../../types/hybridCall";
import { useSchemaField }                from "../../../Layout/useSchemaField";
import { UnreadNotificationContext }     from "../../UnreadNotificationProvider";
import { AvatarListItem }                from "../AvatarListItem/AvatarListItem";
import { DialUser }                      from "./__types__/DialUser";
import { SearchDialUsersQueryVariables } from "./__types__/SearchDialUsersQuery";
import { SearchDialUsersQuery }          from "./__types__/SearchDialUsersQuery";

interface UserListProps {
  onSelect(item: DialUser);
  calls: HybridCall[];
}

export const UserList: FC<UserListProps> = React.memo(function UserList(props) {
  const [search, setSearch] = useState("");
  const { calls } = props;
  const { id } = useContext(UnreadNotificationContext);
  const excludeId = useMemo(() => {
    const ids = [id];
    calls?.map(call => {
      if (call.__typename == "User") {
        ids.push(call.id);
      }
    });

    return ids;
  }, [id, calls]);
  const { options } = useSchemaField("User", "role");
  let [query, setQuery] = useThrottle(search, 1000);
  const objectName = query ? query.trim() : "";
  const where = useMemo(() => {
    const where: UserWhereInput = {
      id: {
        notIn: excludeId
      },
      phoneLines: {
        exists: true
      },
      online: {
        equalTo: true
      },
      onCall: {
        notEqualTo: true
      },
      status: {
        notIn: ["busy", "out_of_work"]
      },
      OR: [
        {
          firstName: { matchesRegex: `.*${objectName}.*`, options: "i" }
        },
        {
          lastName: { matchesRegex: `.*${objectName}.*`, options: "i" }
        },
        {
          email: { matchesRegex: `.*${objectName}.*`, options: "i" }
        },
        {
          objectName: { matchesRegex: `.*${objectName}.*`, options: "i" }
        }
      ]
    };

    return where;
  }, [objectName, excludeId]);
  const { data: { users: { edges = [] } = {} } = {}, loading } = useQuery<SearchDialUsersQuery, SearchDialUsersQueryVariables>(SEARCH_DIAL_USERS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      where,
      first: 15
    }
  });
  useEffect(() => setQuery(search), [search]);
  const filteredPhones = useMemo(() => {
    return edges.filter(e => !!e?.node?.phoneLines?.edges?.length);
  }, [edges]);

  return <Stack direction={"column"} alignItems={"stretch"} style={{ flex: 1 }} spacing={8}>
    <Stack.Item grow={1}>
      <Input placeholder={"Search user"} size={"md"} value={search} onChange={(value: string) => setSearch(value)}/>
    </Stack.Item>
    {
      !!filteredPhones.length
        ?
        filteredPhones.map(({ node }, index) => {
          const { label } = options.find(o => o.value == node.role);
          return <AvatarListItem
            key={index}
            onClick={() => props.onSelect(node)}
            active={node.online}
            src={node.objectIcon}
            title={node.objectName}
            text={label}
          />;
        })
        :
        <EmptyState compact title={"No available users"} subtitle={"Try to find right person in contact section"}
                    icon={"rc_person_off"} style={{ padding: 16 }}/>
    }
  </Stack>;
});

export const SEARCH_DIAL_USERS_QUERY = gql`
  fragment DialUser on User {
    id
    objectId
    objectIcon
    objectName
    firstName
    lastName
    team
    role
    online
    updatedAt
    createdAt
    username
    phoneLines {
      edges {
        node {
          number
        }
      }
    }
  }
  query SearchDialUsersQuery(
    $where:UserWhereInput,
    $first:Int,
  ) {
    users(first: $first,where: $where) {
      count
      edges {
        node {
          ...DialUser
        }
      }
    }
  }
`;

