import React                   from "react";
import { FC }                  from "react";
import { useQuery }            from "@apollo/client";
import { useFormState }        from "@relcu/final-form";
import { Typography }          from "@relcu/rc";
import { Stack }               from "@relcu/rc";
import { RENDER_MAIL_QUERY }   from "../../../../ContentVisualizer";
import { RenderMailVariables } from "../../../../ContentVisualizer/__types__/RenderMail";
import { RenderMail }          from "../../../../ContentVisualizer/__types__/RenderMail";

export const PreviewSubjectRow: FC<any> = React.memo(function PreviewSubjectRow(props) {
  const { values } = useFormState();
  const { data: { renderMail = {} } = {} } = useQuery<RenderMail, RenderMailVariables>(RENDER_MAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: { errorPolicy: "ignore" },
    variables: {
      input: {
        to: values.to?.map(t => t.primaryEmail),
        user: values.from,
        scope: values.scope || undefined,
        proposal: values.loanProposal,
        template: values.content ?? " ",
        subject: values.subject
      }
    }
  });

  return (
    <Stack spacing={16} className={"template-renderer-header"}>
      <Typography color={"secondary"}>
        Subject:
      </Typography>
      <Stack.Item style={{ height: 48, padding: 16 }}>
        <Typography weights={"medium"}>
          {renderMail?.subject}
        </Typography>
      </Stack.Item>
    </Stack>
  );
});
