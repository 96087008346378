import React                      from "react";
import { FC }                     from "react";
import { ChangeEvent }            from "react";
import { useRef }                 from "react";
import { useEffect }              from "react";
import { useCallback }            from "react";
import { gql }                    from "@apollo/client";
import { useMutation }            from "@apollo/client";
import { useFieldArray }          from "@relcu/form";
import { useField, useFormState } from "@relcu/form";
import { useForm }                from "@relcu/form";
import { Field }                  from "@relcu/form";
import { FieldArray }             from "@relcu/form";
import { FieldInputProps }        from "@relcu/form";
import { useLatest }              from "@relcu/ui";
import { classNames }             from "@relcu/ui";
import { BoxItemComponentProps }  from "@relcu/ui";
import { applyBoxItemStyles }     from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { FontIcon }               from "@relcu/ui";
import { FILE_FRAGMENT }          from "../../../graph/operations.graphql";
import { fileToBase64 }           from "../../../utils/helpers";
import { FlyerAttachment }        from "../ActionBar/FlyerAttachment";
import { MsgAttachment }          from "../Msg/MsgAttachments/MsgAttachment";
import { RemoveFile }             from "./__types__/RemoveFile";
import { RemoveFileVariables }    from "./__types__/RemoveFile";
import { UploadFileVariables }    from "./__types__/UploadFile";
import { UploadFile }             from "./__types__/UploadFile";
import { MessengerClasses }       from "./MessengerClasses";
import { Mime }                   from "@relcu/mime";
import "./messenger.css";

export interface MessengerProps extends BoxItemComponentProps {
  objectTypes?: string[];
  required?: boolean;
  canAttachFlyer?: boolean;
  disableFlyer?: boolean;
  maxCharacterLimit?: number;
  name: string;
  onChange?(value);
  canUpdate?: boolean;
  showUpload?: boolean;
}

const objectTypes = [
  "audio/basic",
  "audio/L24",
  "audio/mp4",
  "audio/mpeg",
  "audio/ogg",
  "audio/vorbis",
  "audio/vnd.rn-realaudio",
  "audio/vnd.wave",
  "audio/3gpp",
  "audio/3gpp2",
  "audio/ac3",
  "audio/vnd.wave",
  "audio/webm",
  "audio/amr-nb",
  "audio/amr",
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/3gpp",
  "video/3gpp2",
  "video/3gpp-tt",
  "video/H261",
  "video/H263",
  "video/H263-1998",
  "video/H263-2000",
  "video/H264",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/bmp",
  "text/vcard",
  "text/csv",
  "text/rtf",
  "text/richtext",
  "text/calendar",
  "text/directory",
  "application/pdf"
];
const defaultMessengerProps = {
  maxCharacterLimit: 480,
  objectTypes,
  showUpload: true
};

Messenger.defaultProps = defaultMessengerProps;
export function Messenger(props: MessengerProps) {
  const form = useForm();
  const properties = applyBoxItemStyles<MessengerProps>(props);
  const { className, children, style, objectTypes, onChange, name, required, canUpdate, showUpload, maxCharacterLimit, canAttachFlyer, disableFlyer, ...p } = properties;
  const classes = classNames(MessengerClasses.Messenger, {
    [ MessengerClasses.MessengerDisabled ]: !canUpdate
  }, className);

  const validateAttachments = useCallback((items) => {
    if (items.length > 10) {
      return "Only 10 files may be uploaded at a time.";
    }
    const supportedFiles = [];
    const unSupportedFiles = [];
    items.forEach(value => {
      const type = value.attachment.mimeType || value.attachment.type || Mime.getType(value.attachment.name);
      if (["image/jpeg", "image/gif", "image/png"].includes(type)) {
        supportedFiles.push(value.attachment);
      } else {
        unSupportedFiles.push(value.attachment);
      }
    });
    const supportedFilesSize = supportedFiles.reduce((sum, file) => sum + file.size, 0);
    const unSupportedFilesSize = unSupportedFiles.reduce((sum, file) => sum + file.size, 0);
    const totalFilesSize = supportedFilesSize + unSupportedFilesSize;

    if (unSupportedFilesSize / 1024 > 1500 && items.length > 1) {
      return "Total size of non image attachments can not be greater than 1.5MB";
    }
    if (Math.ceil(totalFilesSize / 1024 / 1024) > 5 && items.length > 1) {
      return "Total size of attachments can not be greater than 5MB";
    }
  }, []);
  const { input: { value: textAreaValue } } = useField(name, { subscription: { value: true } });
  const { submitSucceeded } = useFormState({ subscription: { submitSucceeded: true } });
  const attachment = useFieldArray("attachments");
  const textRef = useRef(null);
  const keysRef = useRef(attachment.fields.length ? [0] : []);
  const counterRef = useRef(attachment.fields.length);

  useEffect(() => {
    if (submitSucceeded) {
      textRef?.current.focus();
    }
  }, [submitSucceeded]);
  function onInputChange(e: ChangeEvent<HTMLTextAreaElement>) {
    if (e.currentTarget.value.length <= maxCharacterLimit) {
      form.change("content", e.currentTarget.value);
      form.change("template", "");
    }
  }

  function addFile(file) {
    keysRef.current.push(++counterRef.current) &&
    attachment.fields.push({ attachment: file });
  }

  function addFlyer(flyer) {
    keysRef.current.push(++counterRef.current) &&
    attachment.fields.push({ attachment: flyer });
  }

  function onPaste(e: ClipboardEvent) {
    e.clipboardData?.files[ 0 ] && addFile(e.clipboardData?.files[ 0 ]);
  }

  useEffect(() => {
    if (canUpdate) {
      window.addEventListener("paste", onPaste);
    }

    return () => window.removeEventListener("paste", onPaste);
  }, []);

  return (
    <Box container direction={"column"} gap={"XXXS"} flex={1}>
      <Box container direction={"column"} className={classes} gap={"XXS"} {...p} >
        <Field name={name}>
          {({ input }) => (
            <textarea
              {...input}
              cols={1}
              ref={textRef}
              spellCheck={true}
              lang={"en"}
              className={MessengerClasses.MessengerTextArea}
              onChange={onInputChange}
              placeholder="Type a message..."/>
          )}
        </Field>
        <FieldArray name="attachments" validate={validateAttachments}>
          {({ fields, meta: { error, valid } }) => (
            <>
              <Box container gap={"XXS"} wrap={"wrap"} className={MessengerClasses.MessengerAttachments}>
                {fields.map((name, index) => {
                  return (
                    <Attachment
                      objectTypes={objectTypes}
                      key={keysRef.current[ index ]}
                      name={`${name}.attachment`}
                      onDelete={() => keysRef.current.splice(index, 1) && fields.remove(index)}
                    />
                  );
                })}
              </Box>
              {typeof error === "string" && <p className={MessengerClasses.MessengerError}>{error}</p>}
              {
                showUpload &&
                <Box container justify={"end"} gap={"XS"} flexGrow={1} className={MessengerClasses.FileInput}>
                  <label>
                    <input
                      disabled={typeof error === "string"}
                      type="file"
                      accept={objectTypes.join(",")}
                      onChange={(e) => {
                        e.target.files[ 0 ] && addFile(e.target.files[ 0 ]);
                        e.target.value = "";
                      }}/>
                    <FontIcon type="attachment"/>
                  </label>
                  {canAttachFlyer && <FlyerAttachment disableFlyer={disableFlyer} onAddFlyer={addFlyer}/>}
                  {children}
                </Box>
              }

            </>
          )}
        </FieldArray>
      </Box>
      {
        maxCharacterLimit &&
        <Box container className={MessengerClasses.MessengerCharacters} justify={"end"}>
          Character {textAreaValue.length}/{maxCharacterLimit}
        </Box>
      }
    </Box>
  );
}

const Attachment: FC<{ name: string, onDelete: () => void, objectTypes: string[] }> = React.memo((props) => {
  const form = useForm();
  const { submitting } = useFormState({ subscription: { submitting: true } });
  const [sendFile, { loading }] = useMutation<UploadFile, UploadFileVariables>(UPLOAD_FILE);
  const [removeFile] = useMutation<RemoveFile, RemoveFileVariables>(REMOVE_FILE);
  const { input, meta } = useField(props.name, {
    validate(value, allValue, meta) {
      if (!value) {
        return;
      }
      const type = value.mimeType || value.type || Mime.getType(value.name);
      if (!props.objectTypes.includes(type)) {
        return "Invalid file type";
      }
      if (value?.size > 5 * 1024 * 1024) {
        return "File is too large (max: 5MB)";
      }
      if (!["image/jpeg", "image/gif", "image/png"].includes(type) && (value?.size / 1024) > 1500) {
        return "File is too large (max: 1.5MB)";
      }
      if (meta.data?.error) {
        return meta.data.error;
      }
    }
  });
  const submittingRef = useLatest(submitting);
  const inputRef = useLatest(input);
  const loadingRef = useLatest(loading);
  useEffect(() => {
    if (inputRef.current.value instanceof File && meta.valid && !loadingRef.current) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        let encoded = fileToBase64(reader);
        try {
          const { data: { uploadFile } } = await sendFile({
            variables: {
              name: inputRef.current.value.name,
              content: encoded,
              type: inputRef.current.value.type || Mime.getType(inputRef.current.value.name)
            }
          });
          inputRef.current.onChange({ ...uploadFile, size: inputRef.current.value.size });
        } catch (e) {
          console.error(e);
          form.mutators.setFieldData(props.name, { error: e.message || "File not uploaded" });
        }
      };
      reader.readAsDataURL(inputRef.current.value);
    }
    return () => {
      if (inputRef.current.value.id && !submittingRef.current) {
        console.info("delete", inputRef.current.value.name);
        removeFile({ variables: { id: inputRef.current.value.id } }).catch(console.error);
      }
    };
  }, []);

  return (
    <MsgAttachment
      attachment={{
        url: input.value.url,
        mimeType: input.value.type || Mime.getType(input.value.name),
        name: input.value.name,
        size: input.value.size
      }}
      uploaded={!loading}
      error={meta.error}
      onDelete={props.onDelete}
    />
  );
});

Attachment.defaultProps = {
  objectTypes
};

export const UPLOAD_FILE = gql`
  ${FILE_FRAGMENT}
  mutation UploadFile($name:String!, $content:Bytes!, $type:String!){
    uploadFile(blob: {name: $name,content: $content,type: $type}) {
      ...File
    }
  }
`;

export const UPLOAD_AVATAR = gql`
  mutation UploadAvatar($url:String,$blob:Blob){
    uploadAvatar(url: $url,blob:$blob) {
      url
    }
  }
`;
export const REMOVE_FILE = gql`
  mutation RemoveFile($id:ID!){
    updateFile(input: {id: $id,fields: {deleted: true}}){
      file{
        id
        objectId
      }
    }
  }
`;
