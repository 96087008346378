import { useSubscription } from "@apollo/client";
import { useMutation }     from "@apollo/client";
import { useQuery }        from "@apollo/client";
import { gql }             from "@apollo/client";
import { useForm }         from "@relcu/final-form";
import { useNavigate }     from "@relcu/react-router";
import { JsonField }       from "@relcu/ui";
import { mode }            from "@relcu/ui";
import { TextArea }        from "@relcu/ui";
import { ButtonSizes }     from "@relcu/ui";
import { ButtonColors }    from "@relcu/ui";
import { ButtonVariants }  from "@relcu/ui";
import { Button }          from "@relcu/ui";
import { SectionClasses }  from "@relcu/ui";
import { useSource }       from "@relcu/ui";
import { AvatarSizes }     from "@relcu/ui";
import { Avatar }          from "@relcu/ui";
import { EmptyList }       from "@relcu/ui";
import { Keys }            from "@relcu/ui";
import { classNames }      from "@relcu/ui";
import { DateTime }        from "luxon";
import { useCallback }     from "react";
import { useMemo }                              from "react";
import React                                    from "react";
import { JsonSectionProps }                     from "@relcu/ui";
import { Box }                                  from "@relcu/ui";
import { SubscriptionEvent }                    from "../../../../types/graphql-global-types";
import { usePermissions }                       from "../../../AccessControl";
import { GetWorksheetLastNotesVariables }       from "./__types__/GetWorksheetLastNotes";
import { GetWorksheetLastNotes }                from "./__types__/GetWorksheetLastNotes";
import { SendWorksheetNewNoteVariables }        from "./__types__/SendWorksheetNewNote";
import { SendWorksheetNewNote }                 from "./__types__/SendWorksheetNewNote";
import { SubscribeWorksheetLastNotesVariables } from "./__types__/SubscribeWorksheetLastNotes";
import { SubscribeWorksheetLastNotes }          from "./__types__/SubscribeWorksheetLastNotes";
import { GRWorkSheetSectionClasses }            from "./GRWorkSheetSectionClasses";

export const GRWorkSheetEditSection = React.memo<JsonSectionProps>(function GRWorkSheetEditSection(props) {
  const { title, view, editable, onDone, onClear,onEdit, className, ...rest } = props;
  const { $object: node, $viewer } = useSource();

  const [note, setNote] = React.useState("");
  const { canUpdate } = usePermissions(node);

  const navigate = useNavigate();
  const {
    data: { notes: { edges = [] } = {} } = {},
    refetch
  } = useQuery<GetWorksheetLastNotes, GetWorksheetLastNotesVariables>(GET_LAST_NOTES, {
    variables: { id: node.id },
    fetchPolicy: "no-cache"
  });
  const [send, { loading }] = useMutation<SendWorksheetNewNote, SendWorksheetNewNoteVariables>(SEND_NEW_NOTE, {
    update(cache, { data: { createNote: { note: node } } }) {
      setNote("");
    }
  });

  useSubscription<SubscribeWorksheetLastNotes, SubscribeWorksheetLastNotesVariables>(SUBSCRIBE_LAST_NOTES, {
    variables: {
      link: node.id
    },
    onData({ client, data: { data: { notes: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
          refetch();
      }
    }
  });
  const data = useMemo(() => {
    return edges?.reverse();
  }, [edges]);

  const form = useForm();
  const onSave = useCallback(async () => {
    const values = form.getState().values;
    const noteToAdd = note.trim();
    if(noteToAdd!==""){
      const {data : {createNote:{note:{createdAt}={}}={}}={}} = await send({
        variables: {
          input: {
            subject: {
              link: node.id
            },
            owner: {
              link: $viewer.id
            },
            text: `Worksheet notes: ${noteToAdd}`
          }
        }
      });
      if(!createdAt) throw new Error("Failed to create a note");
      form.change("lastBenefitWorkSheetNoteDate",createdAt);
      form.change("lastBenefitWorkSheetNote",noteToAdd);
    }
    if(!values.benefitWorkSheetSignOffDate && values.benefitWorkSheetStatus){
      form.change("benefitWorkSheetSignOffDate",new Date());
    }
    form.submit();
  }, [send, note]);
  const classes = classNames(SectionClasses.Section, SectionClasses.EditSection, SectionClasses.EditableSection, className);

  return (
    <Box flex={1} container direction={"column"} gap={"XS"} style={{ maxWidth: "100%" }} className={classes} {...rest}>
      <Box container
           className={SectionClasses.SectionHeader}
           justify={"space-between"}>
        <>{title || null}</>
        <Box container gap={"XXS"} alignItems={"center"} className={SectionClasses.SectionActions}>
          <Button icon={"save"} type="button" onClick={onSave} variant={ButtonVariants.Ghost}
                  color={ButtonColors.White} onlyIcon
                  size={ButtonSizes.Medium}/>
          <Button icon={"clear"} type="button" variant={ButtonVariants.Ghost} color={ButtonColors.White}
                  onClick={onClear || null} onlyIcon size={ButtonSizes.Medium}/>
        </Box>
      </Box>
      <Box>
        {
          props?.fields.map((field, index) => {
            return (
              <JsonField
                key={index}
                {...field}
                component={!["benefitWorkSheetStatus"].includes(field.name) ? "HiddenField" : field.component}
                mode={"edit"}
                name={field.name}
                view={mode(view)}
                label={null}
                flexBasis={"calc(30% - 8px);"}
              />
            );
          })
        }
      </Box>
      <Box
        title="Notes"
        className={GRWorkSheetSectionClasses.Notes}
        gap={"XXXXS"}
      >
        <Box container justify={"space-between"} gap={"XS"} className={classNames(GRWorkSheetSectionClasses.Actions, {
               [ GRWorkSheetSectionClasses.Active ]: !!note?.trim() && !loading
             })}>
          <TextArea
            placeholder={"Add note..."}
            onChange={(value) => (value?.length < 3000 || value == null) && setNote(value)}
            flex={1}
            readOnly={!canUpdate}
            value={note}
            onKeyPress={(e) => {
              if (e.key == Keys.Enter && !e.shiftKey && note?.trim() && !loading) {
                onSave();
              }
            }}
          />
        </Box>
        {data.length == 0 ?
          <Box
            container
            direction={"column"}
            alignItems={"center"}
            justify={"center"}
            className={GRWorkSheetSectionClasses.EmptyView}
            alignSelf={"center"}
            gap={"XXS"} flex={1}
          >
            <EmptyList icon={"rc_note"} content={"Click to \"Add note...\" below to get started"} title={"No notes"}
                       alignSelf={"center"} small/>
          </Box> :
          <Box
            container
            direction={"column"}
            gap={"XS"}
            flex={1}>

            <Box container
                 className={SectionClasses.SectionHeader}
                 justify={"space-between"}>
              Previous notes
            </Box>
            {
              data.map(({ node }, index) =>
                <Box key={index} container gap={"XXS"} className={GRWorkSheetSectionClasses.ListItem}>
                  <Avatar size={AvatarSizes.Small} text={node.owner?.objectName ?? "Relcu"}
                          icon={node.owner?.objectIcon}
                          onClick={() => node.owner && navigate(`/user/${node.owner.objectId}`)}/>
                  <Box container flex={1} direction={"column"} alignItems={"start"}>
                    <span>{DateTime.fromISO(node.createdAt).toFormat("MMM dd, h:mm a")}</span>
                    <Box container direction={"column"}>
                      <p className={GRWorkSheetSectionClasses.ListItemContent}>{node.text.replace("Worksheet notes:","").trim()}</p>
                    </Box>
                  </Box>
                </Box>
              )
            }
          </Box>
        }
      </Box>
    </Box>
  );
});

const GET_LAST_NOTES = gql`
  query GetWorksheetLastNotes($id: ID!) {
    notes(
      last: 10,
      order: [createdAt_ASC]
      where: {
        subject: {
          have: {
            link: $id
          }
        },
        text:{
          matchesRegex: "^Worksheet notes:"
        }
      }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          objectId
          objectIcon
          objectName
          text
          createdAt
          owner {
            id
            objectId
            objectIcon
            objectName
          }
        }
      }
    }
  }
`;
const SEND_NEW_NOTE = gql`
  mutation SendWorksheetNewNote($input:CreateNoteFieldsInput!){
    createNote(input: {
      fields: $input
    }){
      clientMutationId
      note {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;

const SUBSCRIBE_LAST_NOTES = gql`
  subscription SubscribeWorksheetLastNotes($link: ID) {
    notes(where: {subject: {have: {link: $link}}}) {
      event
      node {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        subject {
          ...on Document{
            objectId
            objectIcon
            objectName
          }
        }
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;

