import React                    from "react";
import { FC }                   from "react";
import { useCallback }          from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { FieldArray }           from "@relcu/form";
import { applySmoothDrag }      from "../../../../../../../../utils/helpers";
import { uuid }                 from "../../../../../../../../utils/helpers";
import { parseLimits }          from "../../../utils";
import { Button }               from "@relcu/ui";
import { StepInterface }        from "@relcu/ui";
import { ButtonVariants }       from "@relcu/ui";
import { StepContainer }        from "@relcu/ui";
import { StepHeader }           from "@relcu/ui";
import { StepBody }             from "@relcu/ui";
import { Rule }                 from "./Rule";
import "./rules.css";

export const Rules: FC<StepInterface> = React.memo(function Rules(props) {
  const { title, description, step, ...p } = props;
  const handleDrop = useCallback(async (list, { removedIndex, addedIndex, payload }: {
    removedIndex: number | null;
    addedIndex: number | null;
    payload?: any;
    element?: HTMLElement;
  }, fields: {
    update: (index: number, value) => void
  }) => {
    const result = applySmoothDrag(list, { removedIndex, addedIndex, payload });
    result.forEach((rule, index) => {
      fields.update(index, rule);
    });
  }, []);

  return <StepContainer gap={null} {...p}>
    <FieldArray name={"rules"}>
      {({ fields, meta }) => {
        return <>
          <StepHeader
            step={step}
            title={title}
            description={description}
            action={
              <Button
                icon="add"
                variant={ButtonVariants.Ghost}
                flexShrink={0}
                onClick={() => {
                  fields.push({
                    name: `Rule ${fields.length + 1}`,
                    limits: parseLimits([{ filter: ["*"] }]),
                    sort: []
                  });
                }}>
                Add rule
              </Button>
            }
            edit={false}/>
          <StepBody gap={"XS"}>
            <Container
              orientation={"vertical"}
              groupName="rules"
              autoScrollEnabled
              dragHandleSelector=".rule-drag-handle"
              onDrop={e => handleDrop(fields.value, e, fields)}
              dropPlaceholder={{ showOnTop: true, className: "drop-preview" }}>
              {
                fields.map((name, index) => {
                  return <Draggable key-={fields.value[ index ].slug}>
                    <Rule
                      index={index}
                      name={name}
                      key={index}
                      onClone={() => {
                        const rule = { ...fields.value[ index ] };
                        if (rule.slug) {
                          rule.slug = uuid();
                        }
                        rule.name = `Rule ${fields.length + 1}`;
                        fields.push(rule);
                      }}
                      onDelete={() => fields.remove(index)}/>
                  </Draggable>;
                })
              }
            </Container>
          </StepBody>
        </>;
      }}
    </FieldArray>
  </StepContainer>;
});
