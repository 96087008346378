import React                                       from "react";
import { useState }                                from "react";
import { useEffect }                               from "react";
import { useMemo }                                 from "react";
import { useContext }                              from "react";
import { useReactiveVar }                          from "@apollo/client";
import { ReactiveVar }                             from "@apollo/client/cache/inmemory/reactiveVars";
import { FormSpy }                                 from "@relcu/form";
import { OnChange }                                from "@relcu/form";
import { Field }                                   from "@relcu/form";
import { ReadCell }                                from "@relcu/rc";
import { FieldCell }                               from "@relcu/rc";
import { Button }                                  from "@relcu/rc";
import { CellGroup }                               from "@relcu/rc";
import { HeaderCell }                              from "@relcu/rc";
import { Column }                                  from "@relcu/rc";
import { TableHorizontal }                         from "@relcu/rc";
import { EmptyState }                              from "@relcu/rc";
import { HiddenField }                             from "@relcu/ui";
import { formatNumber }                            from "@relcu/ui";
import { Where }                                   from "@relcu/ui";
import { useSource }                               from "@relcu/ui";
import { FormWhere }                               from "@relcu/ui";
import { usePrevious }                             from "@relcu/ui";
import { LoanEstimateOffer }                       from "../../../../../graph/__types__/LoanEstimateOffer";
import { getFieldRecursion }                       from "../../../../../utils/schemaUtils";
import { GroupsState }                             from "../PricingBetaView";
import { StateRef }                                from "../PricingBetaView";
import { AppraisalFeeCell }                        from "./Cells/AppraisalFeeCell";
import { CondoCertificateFeeCell }                 from "./Cells/CondoCertificateFeeCell";
import { CreditsCell }                             from "./Cells/CreditsCell";
import { DownPaymentCell }                         from "./Cells/DownPaymentCell";
import { ScoreCell }                               from "./Cells/ScoreCell";
import { GroupSummaryCell }                        from "./Cells/GroupSummaryCell";
import { useSchemaField }                          from "../../../useSchemaField";
import { Proposal }                                from "../Proposal";
import { HeadingCell }                             from "./Cells/HeadingCell";
import { Cell }                                    from "./Cells/Cell";
import { LeadSourceCell }                          from "./Cells/LeadSourceCell";
import { LoanAmountCell }                          from "./Cells/LoanAmountCell";
import { LoanCriteriaGroupCell }                   from "./Cells/LoanCriteriaGroupCell";
import { LtvCell }                                 from "./Cells/LtvCell";
import { RateLockCell }                            from "./Cells/RateLockCell";
import { NullValueCell }                           from "./Cells/NullValueCell";
import { DropdownGroupHeadingCell }                from "./Cells/DropdownGroupHeadingCell";
import { PmiSelectCell }                           from "./Cells/PmiCell/PmiSelectCell";
import { ProductCategoryCell }                     from "./Cells/ProductCategoryCell";
import { SubmitCell }                              from "./Cells/SubmitCell/SubmitCell";
import { TitleSelectCell }                         from "./Cells/TitleFeeCell/TitleSelectCell";
import { TotalLoanAmountCell }                     from "./Cells/TotalLoanAmountCell";
import { Offer }                                   from "./Offer";
import { WHEN_SETTLEMENT_FEE_IS_VISIBLE }          from "./offer.conditions";
import { WHEN_TRANSFER_TAX_IS_VISIBLE }            from "./offer.conditions";
import { WHEN_OWNERS_TITLE_IS_VISIBLE }            from "./offer.conditions";
import { WHEN_RECORDING_CHARGES_IS_VISIBLE }       from "./offer.conditions";
import { WHEN_TITLE_INSURANCE_IS_VISIBLE }         from "./offer.conditions";
import { WHEN_PMI_EXISTS }                         from "./offer.conditions";
import { WHEN_DOCUMENT_TYPE_DSCR }                 from "./offer.conditions";
import { WHEN_IN_MERS_FEE_STATES }                 from "./offer.conditions";
import { WHEN_IS_OB_PRICING }                      from "./offer.conditions";
import { WHEN_IS_POLLY_PRICING }                   from "./offer.conditions";
import { WHEN_REFINANCE_OR_SUBORDINATE_FINANCING } from "./offer.conditions";
import { WHEN_AMI_WAIVER }                         from "./offer.conditions";
import { WHEN_LOAN_TERM_ARM }                      from "./offer.conditions";
import { WHEN_RATES_EXISTS }                       from "./offer.conditions";
import { WHEN_IS_STREAMLINE_WO_CREDIT }            from "./offer.conditions";
import { WHEN_TITLE_COMPANY_IS_EDITABLE }          from "./offer.conditions";
import { WHEN_PMI_IS_MONTHLY }                     from "./offer.conditions";
import { WHEN_PMI_IS_NOT_MONTHLY }                 from "./offer.conditions";
import { WHEN_LENDER_CREDITS }                     from "./offer.conditions";
import { WHEN_DISCOUNT_POINT }                     from "./offer.conditions";
import { WHEN_IS_MORTECH_PRICING }                 from "./offer.conditions";
import { WHEN_LENDERS_TITLE_IS_NOT_EMPTY }         from "./offer.conditions";
import { WHEN_FEE_IS_ADDED }                       from "./offer.conditions";
import { WHEN_IS_PURCHASE_FEE_IS_ADDED }           from "./offer.conditions";
import { WHEN_MORTGAGE_INSURANCE_GREATER_THEN_0 }  from "./offer.conditions";
import { WHEN_IS_MANUAL_PRICING }                  from "./offer.conditions";
import { WHEN_IS_FIRST_TIME_HOME_BUYER }           from "./offer.conditions";
import { WHEN_IS_VA }                              from "./offer.conditions";
import { WHEN_IS_FHA }                             from "./offer.conditions";
import { WHEN_IS_FHA_PURCHASE_PRIMARY_RESIDENCE }  from "./offer.conditions";
import { WHEN_IS_STREAMLINE_VA_FHA }               from "./offer.conditions";
import { WHEN_IS_VA_FHA_REFINANCE }                from "./offer.conditions";
import { WHEN_HAS_SECONDARY_FINANCING }            from "./offer.conditions";
import { WHEN_IS_PURCHASE }                        from "./offer.conditions";
import { WHEN_RATE_TERM_OR_CASH_AMOUNT }           from "./offer.conditions";
import { WHEN_CASH_AMOUNT }                        from "./offer.conditions";
import { WHEN_SECONDARY_FINANCING_NOT_NONE }       from "./offer.conditions";
import { WHEN_IN_SURVEY_FEE_STATES }               from "./offer.conditions";
import { WHEN_FF }                                 from "./offer.conditions";
import { WHEN_MIP }                                from "./offer.conditions";
import { WHEN_IN_ATTORNEY_FEE_STATES }             from "./offer.conditions";
import { WHEN_IN_DOC_REVIEW_FEE_STATES }           from "./offer.conditions";
import { WHEN_IS_PESTINSPECTION_FEE }              from "./offer.conditions";
import { WHEN_IS_STREAMLINE_WO_APPRAISAL }         from "./offer.conditions";
import { PricingEngineProvider }                   from "./Offer/PricingEngineProvider";
import { Rate }                                    from "./Rate";
import { NullValuesProvider }                      from "./NullValueProvider";

type closingTypes = {
  all: boolean,
  prepaid: boolean,
  title: boolean,
  credit: boolean,
  other: boolean,
}

export const OfferTable = React.forwardRef((props: {
  groupsStateVar: ReactiveVar<GroupsState>,
  additionalOfferFields?: any[]
}, ref: React.RefObject<StateRef>) => {
  const context = useContext(Proposal.Context);
  const { groupsStateVar, additionalOfferFields = [] } = props;
  const optionalFields = useMemo(() => {
    const moreFees = ["lockInFee", "sellerConcession", "earnestMoneyPaid"];
    if (context?.propertyType?.includes("condo")) {
      moreFees.push("condoCertificateFee");
    }

    return moreFees;
  }, [context?.propertyType]);
  const optionalFieldsToShow = optionalFields.filter((field) => context?.offers?.edges?.some(({ node }) => node[ field ]));
  const [addedMoreFees, setAddedMoreFees] = useState<string[]>(optionalFieldsToShow);
  const prevPropertyType = usePrevious(context.propertyType);
  const { options: pricingEngineOptions } = useSchemaField("LoanEstimateOffer", "pricingEngine");
  const { options: productTypeOptions } = useSchemaField("LoanEstimateOffer", "productType");
  const { options: lockInDaysOptions } = useSchemaField("LoanEstimateOffer", "lockInDays");
  const { options: veteranStatusOptions } = useSchemaField("LoanEstimateOffer", "veteranStatus");
  const { options: documentTypeOptions } = useSchemaField("LoanEstimateOffer", "documentType");
  const { options: loanTermOptions } = useSchemaField("LoanEstimateOffer", "loanTerm");
  const { options: amortizationTypeOptions } = useSchemaField("LoanEstimateOffer", "amortizationType");
  const { options: initialArmTermOptions } = useSchemaField("LoanEstimateOffer", "initialArmTerm");
  const { options: secondaryFinancingOptions } = useSchemaField("LoanEstimateOffer", "secondaryFinancing");
  const { options: viewOptions } = useSchemaField("LoanEstimateOffer", "mortech.view");
  const { options: obProductTypesOptions, defaultValue: obProductTypesDefaultValue } = useSchemaField("LoanEstimateOffer", "optimalBlue.productTypes");
  const { options: automatedUnderwritingSystemOptions, defaultValue: automatedUnderwritingSystemDefaultValue } = useSchemaField("LoanEstimateOffer", "optimalBlue.automatedUnderwritingSystem");
  const { options: prepaymentPenaltyOptions, defaultValue: prepaymentPenaltyDefaultValue } = useSchemaField("LoanEstimateOffer", "optimalBlue.prepaymentPenalty");
  const { options: pollyAutomatedUnderwritingSystemOptions, defaultValue: pollyAutomatedUnderwritingSystemDefaultValue } = useSchemaField("LoanEstimateOffer", "polly.automatedUnderwritingSystem");
  const { options: pollyPrepaymentPenaltyOptions, defaultValue: pollyPrepaymentPenaltyDefaultValue } = useSchemaField("LoanEstimateOffer", "polly.prepaymentPenalty");

  const { options: obViewOptions, defaultValue: obViewDefaultValue } = useSchemaField("LoanEstimateOffer", "optimalBlue.view");
  const offers: LoanEstimateOffer[] = useMemo(() => {
    const offers = context.offers?.edges?.map(({ node }) => node) || [];
    return offers.sort(function (a, b) {
      return (a.createdAt < b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);
    });
  }, [context.offers?.edges]);
  const loanPurpose = useMemo(() => (context.loanPurpose), [context.loanPurpose]);
  const { $settings: { pricing: { closingFees: $closingFees } } } = useSource();
  const { $viewer } = useSource();
  const shouldOpen = useMemo(() => rateSelected(), [offers]);
  const hasOBPricing = useMemo(() => {
    return !!pricingEngineOptions.find((o) => (o.value === "optimalblue"));
  }, [pricingEngineOptions]);
  const hasMortcehPricing = useMemo(() => {
    return !!pricingEngineOptions.find((o) => (o.value === "mortech"));
  }, [pricingEngineOptions]);
  const hasPollyPricing = useMemo(() => {
    return !!pricingEngineOptions.find((o) => (o.value === "polly"));
  }, [pricingEngineOptions]);

  const { isLoanCriteriaOpen, isProductOpen, isOffersOpen, isMonthlyOpen, closing } = useReactiveVar(groupsStateVar);

  useEffect(() => {
    setGroupState("isOffersOpen", shouldOpen);
    setGroupState("isMonthlyOpen", shouldOpen);
    setGroupState("closing", {
      all: shouldOpen,
      prepaid: false,
      title: shouldOpen,
      credit: false,
      other: false
    });
  }, []);

  function scrollToSelectedRate() {
    const selectedRateHeader = Array.from(document.querySelectorAll(".rc-cell-group-header")).find(element => element.textContent.includes("Selected rate"));
    if (selectedRateHeader) {
      selectedRateHeader.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  function setGroupState(name: string, value: boolean | object) {
    const groups = groupsStateVar();
    groupsStateVar({
      ...groups,
      [ name ]: value
    });
  }

  function rateSelected() {
    return offers.some((offer) =>
      (offer.price != null || offer.price != undefined) &&
      (offer.apr != null || offer.apr != undefined) &&
      (offer.rate != null || offer.rate != undefined)
    );
  }

  useEffect(() => {
    const prev = groupsStateVar();
    if (ref.current.isRateSelected) {
      if (prev.isLoanCriteriaOpen) {
        setGroupState("isLoanCriteriaOpen", false);
        setTimeout(() => {
          scrollToSelectedRate();
        }, 400);
      } else {
        scrollToSelectedRate();
      }
    }
  }, [ref.current.isRateSelected]);

  useEffect(() => {
    const shouldOpen = rateSelected();
    const { isOffersOpen, isMonthlyOpen, closing } = groupsStateVar();
    const isOffer = shouldOpen && !isOffersOpen ? true : isOffersOpen;
    const isMonthly = shouldOpen && !isMonthlyOpen ? true : isMonthlyOpen;
    const closingState = shouldOpen && !closing.all ? {
        ...closing,
        all: true,
        title: true
      } :
      closing;
    setGroupState("isOffersOpen", isOffer);
    setGroupState("isMonthlyOpen", isMonthly);
    setGroupState("closing", closingState);

  }, [offers]);

  useEffect(() => {
    setAddedMoreFees((prev) => {
      if (prev.find(p => p.includes("condo")) && prevPropertyType && (prevPropertyType != context.propertyType)) {
        return prev.filter(p => !p.includes("condo")) ?? [];
      } else {
        return prev ?? [];
      }
    });
  }, [context.propertyType]);//reflect only on property type change that's why earnestMoneyPaid and sellerConcession stay persists after regenerations

  const otherMoreFees = useMemo(() => {
    const options = context.loanPurpose == "purchase" ?
      [
        { value: "earnestMoneyPaid", label: "Earnest money paid" },
        { value: "sellerConcession", label: "Seller concession" }
      ] :
      [];

    if (context.propertyType?.includes("condo")) {
      options.push({ value: "condoCertificateFee", label: "Condo certification fee" });
    }

    return options;
  }, [context.loanPurpose, context.propertyType]);

  // const isValidOffer = useMemo(() => offers?.some(o => (o.pricingEngine == "manual" && o.objectId) || (o.pricingEngine != "manual" && o.rate)), [offers]);

  const handleToggleClosing = (key: keyof closingTypes) => {
    setGroupState("closing", {
      ...closing,
      [ key ]: !closing[ key ]
    });
  };
  const handleToggleMoreFees = (value: string) => {
    setAddedMoreFees(prev => {
      const index = prev.indexOf(value);
      if (index > -1) {
        return prev.filter(function (el, i) {
          return index !== i;
        });
      } else {
        return [value, ...prev];
      }
    });
  };
  const handleSubmitFailed = (failed) => {
    if (failed) {
      const { isLoanCriteriaOpen } = groupsStateVar();
      setGroupState("isLoanCriteriaOpen", !isLoanCriteriaOpen ? true : isLoanCriteriaOpen);

      if (!isLoanCriteriaOpen) {
        setTimeout(() => {
          const errors = document.querySelectorAll(".rc-cell-error");
          const container: any = document.querySelector(".rs-content");
          const firstError: any = errors[ 0 ];
          container.scrollTo({ top: firstError.offsetTop - (firstError.offsetHeight * 3), behavior: "smooth" });
        }, 400);
      } else {
        queueMicrotask(() => {
          const errors = document.querySelectorAll(".rc-cell-error");
          const container: any = document.querySelector(".rs-content");
          const firstError: any = errors[ 0 ];
          container.scrollTo({ top: firstError.offsetTop - (firstError.offsetHeight * 3), behavior: "smooth" });
        });
      }

    }
  };
  const handleRatesRedirect = () => {
    ref.current.isRateSelected = false;
  };

  const onLoanPricingEngineChange = (value) => {
    if (value != "mortech") {
      if (!isLoanCriteriaOpen) {
        setGroupState("isLoanCriteriaOpen", true);
      }

      if (!isProductOpen) {
        setGroupState("isProductOpen", true);
      }
    } else if (isLoanCriteriaOpen) {
      setGroupState("isProductOpen", false);
    }
  };

  return <>
    {
      context.id ?
        <NullValuesProvider>
          <TableHorizontal className={"pricing-table"}>
            <Column colspan={12} heading={true} style={{ width: 240 }}>
              <HeaderCell style={{ position: "sticky", top: 0, zIndex: 2 }}>Offers</HeaderCell>
              <Cell style={{ position: "sticky", top: 40, zIndex: 2 }}>Pricing engine</Cell>
              <Cell style={{ position: "sticky", top: 80, zIndex: 2 }}>Product name</Cell>
              <CellGroup
                expanded={isLoanCriteriaOpen}
                onSelect={() => setGroupState("isLoanCriteriaOpen", !isLoanCriteriaOpen)}
                header={(expanded) => (<HeadingCell expanded={expanded}>Loan criteria</HeadingCell>)}>
                <CellGroup
                  level={2}
                  expanded={isProductOpen}
                  onSelect={() => setGroupState("isProductOpen", !isProductOpen)}
                  header={(expanded) => (<HeadingCell expanded={expanded}>Product details</HeadingCell>)}>
                  <Cell>Product type</Cell>
                  <Cell>Is conforming</Cell>
                  <Cell>Amortization type</Cell>
                  <Cell>Term</Cell>
                  <Cell name={"initialArmTerm"}>Initial ARM term</Cell>
                  <Cell name={"documentType"}>Document type</Cell>
                </CellGroup>
                <Cell>Property value</Cell>
                <Cell name={"isHUDReo"}>Is HUD REO</Cell>
                <Cell name={"cashAmount"}>Cash amount</Cell>
                <Cell name={"currentMortgageBalance"}>Current mortgage balance</Cell>
                <Cell>Loan amount</Cell>
                <Cell>Annual property tax</Cell>
                <Cell>Annual property insurance</Cell>
                <Cell name={"mip"}>Finance MIP</Cell>
                <Cell name={"ff"}>Finance F/F</Cell>
                <Cell>Total loan amount</Cell>
                <Cell name={"downPayment"}>Down payment</Cell>
                <Cell>LTV</Cell>
                <Cell name={"cltv"}>CLTV</Cell>
                <Cell name={"dti"}>DTI</Cell>
                <Cell>Secondary financing</Cell>
                <Cell name={"lienAmount"}>Existing/subordinate lien</Cell>
                <Cell>FICO score 1</Cell>
                <Cell>FICO score 2</Cell>
                <Cell>A (Co)borrower is self employed</Cell>
                <Cell>First time buyer</Cell>
                <Cell name={"isStreamLine"}>Is streamline</Cell>
                <Cell name={"withAppraisal"}>With appraisal</Cell>
                <Cell name={"withCredit"}>With credit</Cell>
                <Cell name={"firstUseOfVaProgram"}>First time use of veteran program</Cell>
                <Cell name={"veteranStatus"}>Veteran status</Cell>
                <Cell name={"exempt"}>Exempt</Cell>
                <Cell>Waive escrow</Cell>
                <Cell>Lock in days</Cell>
                <Cell name={"amiWaiverEligibility"}>AMI waiver eligible</Cell>
                <Cell name={"monthlyIncome"}>Borrower's monthly income</Cell>
                <Cell name={"source"}>Source</Cell>
                {((hasMortcehPricing || hasOBPricing)) &&
                  <Cell name={"optimalBlue.view"}>View</Cell>}
                {hasOBPricing && <Cell name={"optimalBlue.productTypes"}>Product types</Cell>}
                {(hasOBPricing || hasPollyPricing) &&
                  <Cell name={"polly.automatedUnderwritingSystem"}>AUW system</Cell>}
                {(hasOBPricing || hasPollyPricing) && <Cell name={"polly.prepaymentPenalty"}>Prepayment penalty</Cell>}
                {(hasOBPricing || hasPollyPricing) && <Cell name={"polly.monthsReserves"}>Months reserves</Cell>}
                {(hasOBPricing || hasPollyPricing) && <Cell name={"polly.debtServiceCoverageRatio"}>DSCR ratio</Cell>}
                {
                  additionalOfferFields.map(({ name, label }) => {
                    return (<Cell key={name}>{label}</Cell>);
                  })
                }
              </CellGroup>
              <Cell style={{ position: "sticky", top: 120, zIndex: 2 }}/>
              <CellGroup
                expanded={isOffersOpen}
                onSelect={() => setGroupState("isOffersOpen", !isOffersOpen)}
                header={(expanded) => (<HeadingCell expanded={expanded}>Selected rate</HeadingCell>)}>
                <Cell>Rate</Cell>
                <Cell>APR</Cell>
                <Cell>Point</Cell>
              </CellGroup>
              <CellGroup
                expanded={isMonthlyOpen}
                headerBordered
                onSelect={() => setGroupState("isMonthlyOpen", !isMonthlyOpen)}
                header={(expanded) => (<HeadingCell expanded={expanded}>Monthly payment</HeadingCell>)}>
                <Cell>Principal & interest</Cell>
                <Cell>Taxes</Cell>
                <Cell>Insurance</Cell>
                <Cell name={"pmiCompany"}>PMI company</Cell>
                <Cell name={"pmi"}>PMI</Cell>
                <Cell name={"monthlyPremium"}>MI</Cell>
              </CellGroup>
              <CellGroup
                expanded={closing.all}
                headerBordered
                level={2}
                onSelect={() => handleToggleClosing("all")}
                header={(expanded) => (<HeadingCell expanded={expanded}>Closing costs</HeadingCell>)}>
                <Cell name={"pmiCompany_rate"}>PMI company</Cell>
                <Cell name={"pmi_rate"}>PMI</Cell>
                <CellGroup
                  headerBordered
                  expanded={closing.prepaid}
                  level={2}
                  onSelect={() => handleToggleClosing("prepaid")}
                  header={(expanded) => (
                    <DropdownGroupHeadingCell
                      expanded={expanded}
                      title={"Prepaid costs"}
                      selected={addedMoreFees}
                      onSelect={handleToggleMoreFees}
                      items={[
                        { value: "lockInFee", label: "Lock in fee" }
                      ]}
                    />
                  )}>
                  <Cell name={"propertyInsuranceYearly"}>Property insurance (yearly)</Cell>

                  <Cell>Property insurance (prepaid)</Cell>
                  <Cell level={3}>Month</Cell>
                  <Cell level={3}>Amount</Cell>

                  <Cell>Property tax (prepaid)</Cell>
                  <Cell level={3}>Month</Cell>
                  <Cell level={3}>Amount</Cell>

                  <Cell>Prepaid interest</Cell>
                  <Cell level={3}>Days</Cell>
                  <Cell level={3}>Amount</Cell>

                  <Where source={{ $fees: addedMoreFees, fee: "lockInFee" }}
                         conditions={WHEN_FEE_IS_ADDED}>
                    <Cell>Lock in fee</Cell>
                  </Where>
                </CellGroup>
                <CellGroup
                  headerBordered
                  expanded={closing.title}
                  level={2}
                  onSelect={() => handleToggleClosing("title")}
                  header={() => (<HeadingCell expanded={closing.title}>Title fees</HeadingCell>)}>
                  <Cell>Title company</Cell>
                  <Cell name={"titleInsurance"}>Title service/ins</Cell>
                  <Cell name={"recordingCharges"}>Recording charges</Cell>
                  <Cell name={"ownersTitle"}>Owner's title</Cell>
                  <Cell name={"transferTax"}>Transfer tax</Cell>
                  <Cell name={"settlementFee"}>Settlement fee</Cell>
                  <Cell name={"lendersTitle"}>Lenders title</Cell>
                </CellGroup>
                <CellGroup
                  headerBordered
                  expanded={closing.credit}
                  level={2}
                  onSelect={() => handleToggleClosing("credit")}
                  header={() => (
                    <HeadingCell expanded={closing.credit}>Credit report & other fees</HeadingCell>)}>
                  <Cell name={"creditReportFee"}>Credit report fee</Cell>
                  <Cell name={"mersFee"}>MERS fee</Cell>
                  <Cell>Electronic document delivery</Cell>
                  <Cell>LoanSafe fee</Cell>
                  <Cell>Verification fee</Cell>
                  <Cell>Transcript fee</Cell>
                </CellGroup>
                <CellGroup
                  headerBordered
                  expanded={closing.other}
                  level={2}
                  onSelect={() => handleToggleClosing("other")}
                  header={(expanded) =>
                    <DropdownGroupHeadingCell
                      expanded={expanded}
                      title={"Other closing options"}
                      onSelect={handleToggleMoreFees}
                      selected={addedMoreFees}
                      items={otherMoreFees}
                    />
                  }>
                  <Cell name={"appraisalFee"}>Appraisal fee</Cell>
                  <Cell>Flood certificate fee</Cell>
                  <Cell>Admin fee</Cell>
                  <Cell name={"lenderCredits"}>Lender credits</Cell>
                  <Cell name={"discountPoints"}>Discount points</Cell>
                  <Cell name={"mip_rate"}>MIP</Cell>
                  <Cell name={"ff_rate"}>Funding fee</Cell>
                  <Cell name={"pestInspectionFee"}>Pest Inspection</Cell>
                  <Cell name={"docReviewFee"}>Doc review fee</Cell>
                  <Cell name={"attorneyFee"}>Attorney fee</Cell>
                  <Cell name={"surveyFee"}>Survey fee</Cell>
                  <Cell name={"subordinationFee"}>Subordinate fee</Cell>
                  <Where source={{ ...context, $fees: addedMoreFees, fee: "condoCertificateFee" }}
                         conditions={WHEN_FEE_IS_ADDED}>
                    <Cell>Condo certification fee</Cell>
                  </Where>
                  <Where source={{ ...context, $fees: addedMoreFees, fee: "earnestMoneyPaid" }}
                         conditions={WHEN_IS_PURCHASE_FEE_IS_ADDED}>
                    <Cell>Earnest money paid</Cell>
                  </Where>
                  <Where source={{ ...context, $fees: addedMoreFees, fee: "sellerConcession" }}
                         conditions={WHEN_IS_PURCHASE_FEE_IS_ADDED}>
                    <Cell>Seller concession</Cell>
                  </Where>
                </CellGroup>
              </CellGroup>
              <Cell>Cash at close</Cell>
            </Column>
            {
              offers.map((offer, index) => (
                <Column key={offer.createdAt.toString()} colspan={12} style={{ width: 240 }}>
                  <PricingEngineProvider index={index}>
                    <Offer data={offer} onRatesRedirect={handleRatesRedirect}>
                      <FieldCell.Select name={"pricingEngine"} style={{ position: "sticky", top: 40, zIndex: 2 }}
                                        data={pricingEngineOptions}/>
                      <OnChange name={"pricingEngine"} children={onLoanPricingEngineChange}/>
                      <ProductCategoryCell/>
                      <CellGroup
                        expanded={isLoanCriteriaOpen}
                        onSelect={() => setGroupState("isLoanCriteriaOpen", !isLoanCriteriaOpen)}
                        header={() => <LoanCriteriaGroupCell/>}
                      >
                        <FormWhere
                          conditions={WHEN_IS_MORTECH_PRICING}>
                          {({ apply }) => {
                            return <CellGroup
                              expanded={isProductOpen}
                              disabled={apply}
                              onSelect={() => setGroupState("isProductOpen", !isProductOpen)}>
                              <FieldCell.Select name={"productType"} data={productTypeOptions}/>
                              <FieldCell.Checkbox name={"conforming"}>
                                Conforming
                              </FieldCell.Checkbox>
                              <FieldCell.Select name={"amortizationType"} data={amortizationTypeOptions}/>
                              <FieldCell.Select name={"loanTerm"} data={loanTermOptions}/>
                              <HiddenField name={"deliveryType"}/>
                              <FormWhere
                                conditions={WHEN_LOAN_TERM_ARM}
                                source={context}
                                nullValue={<NullValueCell name={"initialArmTerm"}/>}>
                                <FieldCell.Select name={"initialArmTerm"} data={initialArmTermOptions}/>
                              </FormWhere>
                              <FormWhere
                                conditions={WHEN_IS_MORTECH_PRICING}
                                source={context}
                                nullValue={<FieldCell.Select name={"documentType"} data={documentTypeOptions}
                                                             disabled={true}/>}>
                                <NullValueCell name={"documentType"}/>
                              </FormWhere>
                            </CellGroup>;
                          }}
                        </FormWhere>
                        <FieldCell.Number required name={"propertyValue"} precision={0}
                                          helperText={"Value can not be equal to zero or empty"} icon={"attach_money"}/>
                        <FormWhere
                          conditions={WHEN_IS_FHA_PURCHASE_PRIMARY_RESIDENCE}
                          source={context}
                          nullValue={<NullValueCell name={"isHUDReo"}/>}>
                          <FieldCell.Checkbox name={"isHUDReo"}>
                            Is HUD Reo
                          </FieldCell.Checkbox>
                        </FormWhere>
                        <FormWhere conditions={WHEN_CASH_AMOUNT} source={context}
                                   nullValue={<NullValueCell name={"cashAmount"}/>}>
                          <FieldCell.Number notNullable helperText={"Value can not be empty"}
                                            name={"cashAmount"} precision={2} icon={"attach_money"}/>
                        </FormWhere>
                        <FormWhere conditions={WHEN_RATE_TERM_OR_CASH_AMOUNT} source={context}
                                   nullValue={<NullValueCell name={"currentMortgageBalance"}/>}>
                          <FieldCell.Number notNullable helperText={"Value can not be empty"}
                                            name={"currentMortgageBalance"} precision={2} icon={"attach_money"}/>
                        </FormWhere>
                        <LoanAmountCell/>
                        <FieldCell.Currency
                          helperText={"Value can not be empty"}
                          dependedFieldName={"propertyValue"}
                          required
                          name={"propertyAnnualTax"}
                          precision={2}
                          icon={"attach_money"}/>
                        <FieldCell.Currency
                          helperText={"Value can not be empty"}
                          dependedFieldName={"propertyValue"}
                          required
                          name={"propertyAnnualInsurance"}
                          precision={2}
                          icon={"attach_money"}/>
                        <FormWhere conditions={WHEN_IS_FHA} nullValue={<NullValueCell name={"mip"}/>}>
                          <FieldCell.Checkbox name={"financeMip"}>
                            <Field name={"mip"}>
                              {({ input }) => formatNumber(input.value, 2)}
                            </Field>
                          </FieldCell.Checkbox>
                        </FormWhere>
                        <FormWhere conditions={WHEN_IS_VA} nullValue={<NullValueCell name={"ff"}/>}>
                          <FieldCell.Checkbox name={"financeFf"}>
                            <Field name={"ff"}>
                              {({ input }) => formatNumber(input.value, 2)}
                            </Field>
                          </FieldCell.Checkbox>
                        </FormWhere>
                        <TotalLoanAmountCell/>
                        <DownPaymentCell/>
                        <LtvCell/>
                        <FormWhere conditions={WHEN_HAS_SECONDARY_FINANCING} nullValue={<NullValueCell name={"cltv"}/>}>
                          <FieldCell.Number name={"cltv"} precision={3} disabled icon={"rc_percent"}/>
                        </FormWhere>
                        <FormWhere conditions={WHEN_DOCUMENT_TYPE_DSCR} nullValue={<NullValueCell name={"dti"}/>}>
                          <FieldCell.Number name={"dti"} required helperText={"Value can not be equal to zero or empty"}
                                            precision={3} icon={"rc_percent"}/>
                        </FormWhere>
                        <FieldCell.Select name={"secondaryFinancing"} data={secondaryFinancingOptions}/>
                        <FormWhere conditions={WHEN_REFINANCE_OR_SUBORDINATE_FINANCING} source={context}
                                   nullValue={<NullValueCell name={"lienAmount"}/>}>
                          <FieldCell.Number required={loanPurpose == "purchase"}
                                            helperText={"Value can not be equal to zero or empty"}
                                            name={"lienAmount"} precision={2} icon={"attach_money"}/>
                        </FormWhere>
                        <ScoreCell name={"fico"}/>
                        <ScoreCell name={"fico1"}/>
                        <FieldCell.Checkbox name={"selfEmployed"}>
                          Self employed
                        </FieldCell.Checkbox>
                        <FieldCell.Checkbox name={"firstTimeHomeBuyer"}>
                          First time buyer
                        </FieldCell.Checkbox>
                        <FormWhere conditions={WHEN_IS_VA_FHA_REFINANCE} source={context}
                                   nullValue={<NullValueCell name={"isStreamLine"}/>}>
                          <FieldCell.Checkbox name={"isStreamLine"}>Is StreamLine</FieldCell.Checkbox>
                        </FormWhere>
                        <FormWhere conditions={WHEN_IS_STREAMLINE_VA_FHA}
                                   nullValue={<NullValueCell name={"withAppraisal"}/>}>
                          <FieldCell.Checkbox name={"withAppraisal"}>With Appraisal</FieldCell.Checkbox>
                        </FormWhere>
                        <FormWhere conditions={WHEN_IS_STREAMLINE_VA_FHA}
                                   nullValue={<NullValueCell name={"withCredit"}/>}>
                          <FieldCell.Checkbox name={"withCredit"}>With Credit</FieldCell.Checkbox>
                        </FormWhere>
                        <FormWhere conditions={WHEN_IS_VA} nullValue={<NullValueCell name={"firstUseOfVaProgram"}/>}>
                          <FieldCell.Checkbox name={"firstUseOfVaProgram"}>
                            First time use of va program
                          </FieldCell.Checkbox>
                        </FormWhere>
                        <FormWhere conditions={WHEN_IS_VA} nullValue={<NullValueCell name={"veteranStatus"}/>}>
                          <FieldCell.Select name={"veteranStatus"} required data={veteranStatusOptions}/>
                        </FormWhere>
                        <FormWhere conditions={WHEN_IS_VA} nullValue={<NullValueCell name={"exempt"}/>}>
                          <FieldCell.Checkbox name={"exempt"}>
                            Exempt
                          </FieldCell.Checkbox>
                        </FormWhere>
                        <FieldCell.Checkbox name={"waiveEscrow"}>
                          Waive escrow
                        </FieldCell.Checkbox>
                        <FieldCell.Select name={"lockInDays"} required data={lockInDaysOptions}
                        />
                        <FormWhere conditions={WHEN_IS_FIRST_TIME_HOME_BUYER}
                                   nullValue={<NullValueCell name={"amiWaiverEligibility"}/>}>
                          <FieldCell.Checkbox name={"amiWaiverEligibility"}>
                            AMI waiver eligibility
                          </FieldCell.Checkbox>
                        </FormWhere>
                        <FormWhere conditions={WHEN_AMI_WAIVER} source={context}
                                   nullValue={<NullValueCell name={"monthlyIncome"}/>}>
                          <FieldCell.Number name={"monthlyIncome"} required={false} icon={"attach_money"}
                                            helperText={"Borrower's monthly qualifying income"} precision={2}/>
                        </FormWhere>
                        <LeadSourceCell/>
                        {(hasMortcehPricing || hasOBPricing) &&
                          <FormWhere conditions={WHEN_IS_MORTECH_PRICING}
                                     nullValue={<FormWhere conditions={WHEN_IS_OB_PRICING}
                                                           nullValue={<NullValueCell name={"optimalBlue.view"}/>}>
                                       <FieldCell.Select name={"optimalBlue.view"} data={obViewOptions}
                                                         defaultValue={obViewDefaultValue}/>
                                     </FormWhere>}>
                            <FieldCell.Select name={"mortech.view"} required data={viewOptions}/>
                          </FormWhere>
                        }
                        {
                          hasOBPricing &&
                          <FormWhere conditions={WHEN_IS_OB_PRICING}
                                     nullValue={<NullValueCell name={"optimalBlue.productTypes"}/>}>
                            <FieldCell.CheckPicker name={"optimalBlue.productTypes"}
                                                   required
                                                   data={obProductTypesOptions}
                                                   defaultValue={obProductTypesDefaultValue}/>
                          </FormWhere>
                        }
                        {
                          (hasOBPricing || hasPollyPricing) &&
                          <FormWhere conditions={WHEN_IS_OB_PRICING}
                                     nullValue={<FormWhere conditions={WHEN_IS_POLLY_PRICING} nullValue={<NullValueCell
                                       name={"polly.automatedUnderwritingSystem"}/>}>
                                       <FieldCell.Select name={"polly.automatedUnderwritingSystem"}
                                                         data={pollyAutomatedUnderwritingSystemOptions}
                                                         defaultValue={pollyAutomatedUnderwritingSystemDefaultValue}/>
                                     </FormWhere>}>
                            <FieldCell.Select name={"optimalBlue.automatedUnderwritingSystem"}
                                              data={automatedUnderwritingSystemOptions}
                                              defaultValue={automatedUnderwritingSystemDefaultValue}/>
                          </FormWhere>
                        }
                        {
                          (hasOBPricing || hasPollyPricing) &&
                          <FormWhere conditions={WHEN_IS_OB_PRICING}
                                     nullValue={<FormWhere conditions={WHEN_IS_POLLY_PRICING} nullValue={<NullValueCell
                                       name={"polly.prepaymentPenalty"}/>}>
                                       <FieldCell.Select name={"polly.prepaymentPenalty"}
                                                         data={pollyPrepaymentPenaltyOptions}
                                                         defaultValue={pollyPrepaymentPenaltyDefaultValue}/>
                                     </FormWhere>}>
                            <FieldCell.Select name={"optimalBlue.prepaymentPenalty"} data={prepaymentPenaltyOptions}
                                              defaultValue={prepaymentPenaltyDefaultValue}/>
                          </FormWhere>
                        }
                        {
                          (hasOBPricing || hasPollyPricing) &&
                          <FormWhere conditions={WHEN_IS_OB_PRICING} source={context}
                                     nullValue={<FormWhere conditions={WHEN_IS_POLLY_PRICING}
                                                           nullValue={<NullValueCell name={"polly.monthsReserves"}/>}>
                                       <FieldCell.Number name={"polly.monthsReserves"} required={false}
                                                         helperText={"Months reserves"} precision={0}/>
                                     </FormWhere>}>
                            <FieldCell.Number name={"optimalBlue.monthsReserves"} required={false}
                                              helperText={"Months reserves"} precision={0}/>
                          </FormWhere>
                        }
                        {
                          (hasOBPricing || hasPollyPricing) &&
                          <FormWhere conditions={WHEN_IS_OB_PRICING} source={context}
                                     nullValue={<FormWhere conditions={WHEN_IS_POLLY_PRICING} nullValue={<NullValueCell
                                       name={"polly.debtServiceCoverageRatio"}/>}>
                                       <FieldCell.Number name={"polly.debtServiceCoverageRatio"} required={false}
                                                         icon={"rc_percent"}
                                                         helperText={"DSCR ratio"} precision={3}/>
                                     </FormWhere>}>
                            <FieldCell.Number name={"optimalBlue.debtServiceCoverageRatio"} required={false}
                                              icon={"rc_percent"}
                                              helperText={"DSCR ratio"} precision={3}/>
                          </FormWhere>
                        }
                        {
                          additionalOfferFields.map(({ name, conditions, component }, index) => {
                            const field = getFieldRecursion("LoanEstimateOffer", name);
                            if (!field) {
                              return <ReadCell justify={"end"}>
                                —
                              </ReadCell>;
                            }
                            let cell = <FieldCell.Text name={name} required={field.required}/>;
                            switch (component) {
                              case "Number" :
                                cell = <FieldCell.Number name={name} required={field.required}/>;
                                break;
                              case "Checkbox" :
                                cell = <FieldCell.Checkbox name={name} required={field.required}/>;
                                break;
                              case "Select" :
                                cell = <FieldCell.Select name={name} required={field.required} data={field.options}
                                                         defaultValue={field.defaultValue}/>;
                                break;
                              case "CheckPicker" :
                                cell = <FieldCell.CheckPicker name={name} required={field.required} data={field.options}
                                                              defaultValue={field.defaultValue}/>;
                                break;
                              case "Read" :
                                cell = <FieldCell.Read name={name}/>;
                                break;
                            }
                            return (conditions ?
                              <FormWhere
                                key={`${name}_${index}`}
                                conditions={conditions}
                                nullValue={
                                  <ReadCell justify={"end"}>
                                    —
                                  </ReadCell>
                                }
                                source={{ $viewer }}>
                                <>{cell}</>
                              </FormWhere> : <>{cell}</>);
                          })
                        }
                      </CellGroup>
                      <FormSpy
                        subscription={{ submitFailed: true }}
                        onChange={(formState) => handleSubmitFailed(formState.submitFailed)}/>
                      <FormSpy
                        subscription={{ values: true, dirty: true, dirtyFields: true }}>
                        {
                          ({ values, dirty, dirtyFields }) => {
                            const isDirty = useMemo(() => dirty && (Object.keys(dirtyFields).length > 1 || !dirtyFields[ "objectName" ]), [dirty, dirtyFields]);
                            return <SubmitCell style={{ position: "sticky", top: 120, zIndex: 2 }}
                                               inert={values.pricingEngine == "manual" && !isDirty}>
                              <Button size={"xs"}
                                      disabled={values.pricingEngine == "manual" && !isDirty}
                                      type={"submit"}>
                                {
                                  !isDirty && offer.price != null ? "SELECT RATE" :
                                    offer.price == null ?
                                      "GET RATES" :
                                      "UPDATE RATES"
                                }
                              </Button>
                            </SubmitCell>;
                          }
                        }
                      </FormSpy>
                      <FormSpy subscription={{ submitting: true, dirty: true, dirtyFields: true }}>
                        {({ submitting, dirty, dirtyFields }) => {
                          const isDirty = useMemo(() => dirty && (Object.keys(dirtyFields).length > 1 || !dirtyFields[ "objectName" ]), [dirty, dirtyFields]);
                          return <Rate data={offer} fees={addedMoreFees} isDirty={isDirty}
                                       optionalFields={optionalFields}>
                            <CellGroup
                              disabled={isDirty || !offer.objectId}
                              expanded={isOffersOpen}
                              onSelect={() => setGroupState("isOffersOpen", !isOffersOpen)}>
                              {
                                (() => {
                                  if (submitting && !ref.current.offers[ offer?.objectId ]) {
                                    return <RateLockCell reason={"loading"}/>; //loading
                                  } else if (isDirty && offer.objectId && !submitting) {
                                    return <RateLockCell reason={"change"}/>; //change
                                  } else if (!submitting && !offer.rate && offer.pricingEngine != "manual") {
                                    return <RateLockCell reason={"noFetch"}/>; // not fetched or fetching result is empty
                                  } else {
                                    return <>
                                      <FormWhere conditions={WHEN_IS_MANUAL_PRICING}>
                                        {({ apply }) => <FieldCell.Number name={"rate"} precision={3}
                                                                          notNullable
                                                                          icon={apply ? "rc_percent" : "rc_lock"}
                                                                          plaintext={!apply}/>}
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_IS_MANUAL_PRICING}>
                                        {({ apply }) => <FieldCell.Number name={"apr"} precision={3}
                                                                          notNullable
                                                                          icon={apply ? "rc_percent" : "rc_lock"}
                                                                          plaintext={!apply}/>}
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_IS_MANUAL_PRICING}>
                                        {({ apply }) => <FieldCell.Number name={"price"} precision={3}
                                                                          notNullable
                                                                          icon={apply ? "rc_percent" : "rc_lock"}
                                                                          plaintext={!apply}/>}
                                      </FormWhere>
                                    </>;
                                  }
                                })()
                              }
                            </CellGroup>
                            <FormWhere conditions={WHEN_RATES_EXISTS}>
                              {
                                ({ apply }) => (
                                  <CellGroup
                                    expanded={isMonthlyOpen}
                                    headerBordered
                                    disabled={isDirty || !offer.objectId || !apply}
                                    onSelect={() => setGroupState("isMonthlyOpen", !isMonthlyOpen)}
                                    header={() => <GroupSummaryCell name={"monthlyPaymentTotal"}/>}>
                                    <FieldCell.Number plaintext icon={"rc_lock"} name={"pi"}/>
                                    <FieldCell.Number icon={"attach_money"} name={"taxes"}/>
                                    <FieldCell.Number icon={"attach_money"} name={"insurance"}/>
                                    <FormWhere conditions={WHEN_PMI_IS_MONTHLY}
                                               source={{ ...context }}
                                               nullValue={<NullValueCell name={"pmiCompany"}/>}>
                                      <PmiSelectCell/>
                                    </FormWhere>
                                    <FormWhere conditions={WHEN_PMI_EXISTS}
                                               source={{ ...context }}
                                               nullValue={<NullValueCell name={"pmi"}/>}>
                                      <FieldCell.Number icon={"attach_money"} plaintext name={"pmi"}/>
                                    </FormWhere>
                                    <FormWhere conditions={WHEN_MORTGAGE_INSURANCE_GREATER_THEN_0}
                                               nullValue={<NullValueCell name={"monthlyPremium"}/>}>
                                      <FieldCell.Number plaintext icon={"attach_money"} name={"monthlyPremium"}/>
                                    </FormWhere>
                                  </CellGroup>
                                )
                              }
                            </FormWhere>
                            <FormWhere conditions={WHEN_RATES_EXISTS}>
                              {
                                ({ apply }) => (
                                  <CellGroup
                                    expanded={closing.all}
                                    headerBordered
                                    disabled={isDirty || !offer.objectId || !apply}
                                    onSelect={() => handleToggleClosing("all")}
                                    header={() => <GroupSummaryCell name={"closingCostTotal"}/>}>
                                    <FormWhere
                                      conditions={WHEN_PMI_IS_NOT_MONTHLY}
                                      source={{ ...context }}
                                      nullValue={
                                        <NullValueCell name={"pmiCompany_rate"}/>
                                      }>
                                      <PmiSelectCell/>
                                    </FormWhere>
                                    <FormWhere
                                      conditions={WHEN_PMI_IS_NOT_MONTHLY}
                                      source={{ ...context }}
                                      nullValue={
                                        <NullValueCell name={"pmi_rate"}/>
                                      }>
                                      <FieldCell.Number icon={"attach_money"} plaintext name={"pmi"}/>
                                    </FormWhere>
                                    <CellGroup
                                      headerBordered
                                      disabled={isDirty || !offer.objectId}
                                      expanded={closing.prepaid}
                                      onSelect={() => handleToggleClosing("prepaid")}
                                      header={() => <GroupSummaryCell name={"prepaidCostTotal"}/>}>
                                      <FormWhere conditions={WHEN_IS_PURCHASE} source={context}
                                                 nullValue={<NullValueCell name={"propertyInsuranceYearly"}/>}>
                                        <FieldCell.Number justify={"end"} name={"propertyInsuranceYearly"}/>
                                      </FormWhere>
                                      <FieldCell.Number plaintext name={"propertyInsurancePrepaidTotal"}
                                                        icon={"functions"}
                                                        helperText={"Property insurance = month x amount"}/>
                                      <FieldCell.Number
                                        name={"propertyInsurancePrepaidMonth"}
                                        precision={0}
                                        validate={(value) => ((value > 12 || value < 1) && "Please indicate the number of months")}/>
                                      <FieldCell.Number name={"propertyInsurancePrepaidAmount"}
                                                        icon={"functions"}
                                                        helperText={"Annual property insurance / 12"}/>
                                      <FieldCell.Number plaintext name={"propertyTaxTotal"} icon={"functions"}
                                                        helperText={"Property tax = month x amount"}/>
                                      <FieldCell.Number
                                        name={"propertyTaxMonth"}
                                        precision={0}
                                        validate={(value) => ((value > 12 || value < 1) && "Please indicate the number of months")}/>
                                      <FieldCell.Number name={"propertyTaxAmount"}
                                                        helperText={"Annual property tax / 12"} icon={"functions"}/>

                                      <FieldCell.Number plaintext name={"prepaidInterestTotal"} icon={"functions"}
                                                        helperText={"Prepaid interest = day x amount"}/>
                                      <FieldCell.Number name={"prepaidInterestDays"} precision={0}/>
                                      <FieldCell.Number plaintext name={"prepaidInterestAmount"}
                                                        helperText={"Monthly interest / 30"} icon={"functions"}/>
                                      <Where source={{ $fees: addedMoreFees, fee: "lockInFee" }}
                                             conditions={WHEN_FEE_IS_ADDED}>
                                        <FieldCell.Number name={"lockInFee"}/>
                                      </Where>
                                    </CellGroup>
                                    <CellGroup
                                      headerBordered
                                      disabled={isDirty || !offer.objectId}
                                      expanded={closing.title}
                                      onSelect={() => handleToggleClosing("title")}
                                      header={() => <GroupSummaryCell name={"titleFeeTotal"}/>}>
                                      <TitleSelectCell/>
                                      <FormWhere conditions={WHEN_TITLE_INSURANCE_IS_VISIBLE}
                                                 nullValue={<NullValueCell name={"titleInsurance"}/>}>
                                        <FormWhere conditions={WHEN_TITLE_COMPANY_IS_EDITABLE}>
                                          {
                                            ({ apply }) => <FieldCell.Number plaintext={!apply}
                                                                             icon={!apply ? "rc_lock" : "attach_money"}
                                                                             helperText={"Value can not be empty"}
                                                                             notNullable
                                                                             name={"titleInsurance"}/>
                                          }
                                        </FormWhere>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_RECORDING_CHARGES_IS_VISIBLE}
                                                 nullValue={<NullValueCell name={"recordingCharges"}/>}>
                                        <FormWhere conditions={WHEN_TITLE_COMPANY_IS_EDITABLE}>
                                          {({ apply }) => <FieldCell.Number plaintext={!apply}
                                                                            icon={!apply ? "rc_lock" : "attach_money"}
                                                                            helperText={"Value can not be empty"}
                                                                            notNullable
                                                                            name={"recordingCharges"}/>}
                                        </FormWhere>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_OWNERS_TITLE_IS_VISIBLE}
                                                 nullValue={<NullValueCell name={"ownersTitle"}/>}>
                                        <FormWhere conditions={WHEN_TITLE_COMPANY_IS_EDITABLE}>
                                          {({ apply }) => <FieldCell.Number plaintext={!apply}
                                                                            helperText={"Value can not be empty"}
                                                                            notNullable
                                                                            icon={!apply ? "rc_lock" : "attach_money"}
                                                                            name={"ownersTitle"}/>}
                                        </FormWhere>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_TRANSFER_TAX_IS_VISIBLE}
                                                 nullValue={<NullValueCell name={"transferTax"}/>}>
                                        <FormWhere conditions={WHEN_TITLE_COMPANY_IS_EDITABLE}>
                                          {({ apply }) => <FieldCell.Number plaintext={!apply}
                                                                            helperText={"Value can not be empty"}
                                                                            notNullable
                                                                            icon={!apply ? "rc_lock" : "attach_money"}
                                                                            name={"transferTax"}/>}
                                        </FormWhere>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_SETTLEMENT_FEE_IS_VISIBLE}
                                                 nullValue={<NullValueCell name={"settlementFee"}/>}>
                                        <FormWhere conditions={WHEN_TITLE_COMPANY_IS_EDITABLE}>
                                          {({ apply }) => <FieldCell.Number plaintext={!apply}
                                                                            helperText={"Value can not be empty"}
                                                                            notNullable
                                                                            icon={!apply ? "rc_lock" : "attach_money"}
                                                                            name={"settlementFee"}/>}
                                        </FormWhere>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_LENDERS_TITLE_IS_NOT_EMPTY}
                                                 nullValue={<NullValueCell name={"lendersTitle"}/>}>
                                        <FormWhere conditions={WHEN_TITLE_COMPANY_IS_EDITABLE}>
                                          {({ apply }) => <FieldCell.Number plaintext={!apply}
                                                                            helperText={"Value can not be empty"}
                                                                            notNullable
                                                                            icon={!apply ? "rc_lock" : "attach_money"}
                                                                            name={"lendersTitle"}/>}
                                        </FormWhere>
                                      </FormWhere>
                                    </CellGroup>
                                    <CellGroup
                                      headerBordered
                                      disabled={isDirty || !offer.objectId}
                                      expanded={closing.credit}
                                      onSelect={() => handleToggleClosing("credit")}
                                      header={() => <GroupSummaryCell name={"creditReportTotal"}/>}>
                                      <FormWhere conditions={WHEN_IS_STREAMLINE_WO_CREDIT}
                                                 nullValue={<NullValueCell name={"creditReportFee"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"creditReportFee"}/>
                                      </FormWhere>
                                      <FormWhere
                                        conditions={WHEN_IN_MERS_FEE_STATES}
                                        source={{ $mersFeeStates: $closingFees.mersFeeStates, $state: context.propertyState }}
                                        nullValue={<NullValueCell name={"mersFee"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"mersFee"}/>
                                      </FormWhere>
                                      <FieldCell.Number icon={"attach_money"} name={"electronicDocumentDelivery"}/>
                                      <FieldCell.Number icon={"attach_money"} name={"loanSafeFee"}/>
                                      <FieldCell.Number icon={"attach_money"} name={"verificationFee"}/>
                                      <FieldCell.Number icon={"attach_money"} name={"transcriptFee"}/>
                                    </CellGroup>
                                    <CellGroup
                                      headerBordered
                                      disabled={isDirty || !offer.objectId}
                                      expanded={closing.other}
                                      onSelect={() => handleToggleClosing("other")}
                                      header={() => <GroupSummaryCell name={"otherCostTotal"}/>}>
                                      <FormWhere conditions={WHEN_IS_STREAMLINE_WO_APPRAISAL}
                                                 nullValue={<NullValueCell name={"appraisalFee"}/>}>
                                        <AppraisalFeeCell/>
                                      </FormWhere>
                                      <FieldCell.Number icon={"attach_money"} name={"floodCertificationFee"}/>
                                      <FieldCell.Number icon={"attach_money"} name={"adminFee"}
                                                        plaintext={!$closingFees.adminFee.editable} notNullable/>
                                      <FormWhere conditions={WHEN_LENDER_CREDITS}
                                                 nullValue={<NullValueCell name={"lenderCredits"}/>}>
                                        <CreditsCell name={"lenderCredits"}/>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_DISCOUNT_POINT}
                                                 nullValue={<NullValueCell name={"discountPoints"}/>}>
                                        <CreditsCell name={"discountPoints"}/>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_MIP} nullValue={<NullValueCell name={"mip_rate"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"mip"}/>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_FF} nullValue={<NullValueCell name={"ff_rate"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"ff"}/>
                                      </FormWhere>
                                      <FormWhere
                                        conditions={WHEN_IS_PESTINSPECTION_FEE}
                                        source={{ $pestInspectionFeeStates: $closingFees.pestInspectionFeeStates, $state: context.propertyState }}
                                        nullValue={<NullValueCell name={"pestInspectionFee"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"pestInspectionFee"}/>
                                      </FormWhere>
                                      <FormWhere
                                        conditions={WHEN_IN_DOC_REVIEW_FEE_STATES}
                                        source={{ $docReviewFeeStates: $closingFees.docReviewFeeStates, $state: context.propertyState }}
                                        nullValue={<NullValueCell name={"docReviewFee"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"docReviewFee"}/>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_IN_ATTORNEY_FEE_STATES}
                                                 source={{ ...context, $attorneyFeeStates: $closingFees.attorneyFeeStates, $state: context.propertyState }}
                                                 nullValue={<NullValueCell name={"attorneyFee"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"attorneyFee"}/>
                                      </FormWhere>
                                      <FormWhere
                                        conditions={WHEN_IN_SURVEY_FEE_STATES}
                                        source={{ $surveyFeeStates: $closingFees.surveyFeeStates, $state: context.propertyState }}
                                        nullValue={<NullValueCell name={"surveyFee"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"surveyFee"}/>
                                      </FormWhere>
                                      <FormWhere
                                        conditions={WHEN_SECONDARY_FINANCING_NOT_NONE}
                                        nullValue={<NullValueCell name={"subordinationFee"}/>}>
                                        <FieldCell.Number icon={"attach_money"} name={"subordinationFee"}/>
                                      </FormWhere>
                                      <HiddenField name={"condoCertificateFee"}/>
                                      <FormWhere conditions={WHEN_FEE_IS_ADDED}
                                                 source={{ $fees: addedMoreFees, fee: "condoCertificateFee" }}>
                                        <CondoCertificateFeeCell/>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_IS_PURCHASE_FEE_IS_ADDED}
                                                 source={{ ...context, $fees: addedMoreFees, fee: "earnestMoneyPaid" }}>
                                        <FieldCell.Number icon={"attach_money"} name={"earnestMoneyPaid"}/>
                                      </FormWhere>
                                      <FormWhere conditions={WHEN_IS_PURCHASE_FEE_IS_ADDED}
                                                 source={{ ...context, $fees: addedMoreFees, fee: "sellerConcession" }}>
                                        <FieldCell.Number icon={"attach_money"} name={"sellerConcession"}/>
                                      </FormWhere>
                                    </CellGroup>
                                  </CellGroup>
                                )
                              }
                            </FormWhere>
                            <FieldCell.Number plaintext icon={"attach_money"} name={"cashToClose"}/>
                          </Rate>;
                        }}
                      </FormSpy>
                    </Offer>
                  </PricingEngineProvider>
                </Column>
              ))
            }
          </TableHorizontal>
        </NullValuesProvider>
        :
        <EmptyState
          icon={"rc_price"}
          title={"Save criteria to start working on offers"}
        />
    }
  </>;
});
